import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadClientTypeListFailed,
  loadClientTypeListSuccessful,
  loadClientTypeListBegin,
} from '../actions';

export default function* loadClientTypeList(action) {
  try {
    const url = `${BACKEND_URL}/client-types`;
    yield put(loadClientTypeListBegin());
    const dataResponse = yield makeRequest(url, {}, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadClientTypeListSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadClientTypeListFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadClientTypeListFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
