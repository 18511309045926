import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './hosts-page.scss';
import { useSearchParams } from 'react-router-dom';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInput from '../../../components/fields/TextInput/TextInput';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import HostGrid from './HostsGrid';
import { loadHostById, loadHostList } from '../actions';
import HostStatus from './HostStatus';
import YandexMap from '../../../components/base/Map/YandexMap';
import {
  responseLogWithNotification,
  getPaginationOpts,
  useUpdatePeriod,
  getJsonFromStorage,
} from '../../../utils/utils';
import { getHostData } from '../reducer';
import { getClientData } from '../../Clients/reducer';
import { getZoneData } from '../../Zones/reducer';
import { getSystemData } from '../../System/reducer';
import { loadZoneList } from '../../Zones/actions';
import Loader from '../../../components/base/Loader/Loader';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { loadClientTypeList } from '../../Clients/actions';

export default function HostsPage() {
  // const tabOptions = [{ label: 'Таблица', value: 1 },
  //   { label: 'Схема', value: 2 }];
  const dispatch = useDispatch();
  const [selectedTab, isSelectedTab] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { updatePeriod } = useSelector(getSystemData);
  const host_id = searchParams.get('host_id');
  // const saveTab = item => {
  //   isSelectedTab(item.value);
  // };
  const {
    hostList, hostListMeta, loadingHostList, loadingActionHistory, loadingHostActivitiesList,
  } = useSelector(getHostData);
  const { clientTypeList } = useSelector(getClientData);
  const { zoneList, loadingZoneList } = useSelector(getZoneData);

  const defaultFilterValues = {
    search: '',
    name: '',
    zones: '',
    group: '',
    lpn: '',
    typeClient: '',
    date: '',
    completed_at_to: '',
    completed_at_from: '',
  };

  const {
    control, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: getJsonFromStorage('host-filter', { ...defaultFilterValues }) });

  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));
  const zoneOptions = zoneList.map(zone => ({ name: zone.name, value: zone.id }));

  const handlerClickRow = item => {
    setSearchParams({ host_id: item.id });
  };
  const clearSelected = () => {
    setSearchParams({});
    setSelectedRow(null);
  };

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(hostListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: {
        ...filterData,
        with_active_components: 1,
        with_actions: 1,
        with_parking_zones: 1,
      },
    };
    const p1 = dispatch(loadHostList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => {
      loadData();
      localStorage.setItem('host-filter', JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(loadZoneList()).catch(responseLogWithNotification);
    dispatch(loadClientTypeList()).catch(responseLogWithNotification);
  }, []);

  useEffect(() => {
    if (host_id) {
      dispatch(loadHostById(host_id)).then(result => setSelectedRow(result.data)).catch(responseLogWithNotification);
    }
  }, [host_id]);

  return (
    <div className="hosts-page">
      <div className="hosts-wrapper">
        <div className="hosts-inputs-box">
          <div className={classNames('hosts-info', { variant: selectedRow })}>
            {
                selectedRow ? (
                  <div className="path">
                    <span className="path-main" onClick={clearSelected}>Мониторинг хостов</span> / <span>Статус хоста</span>
                  </div>
                ) : null
              }
            <PageHeader header={selectedRow ? 'Статус хоста' : 'Мониторинг хостов'}>
              <div className="header-loader"><Loader loading={loadingZoneList || loadingHostList || loadingActionHistory || loadingHostActivitiesList} /></div>
            </PageHeader>
            {/* { !selectedRow ? (
              <TabMenu
                selectedTab={selectedTab}
                options={tabOptions}
                onSelectOptions={item => { saveTab(item); }}
              />
            ) : null } */}
          </div>
          {
            !selectedRow ? (
              <div className="hosts-inputs">
                <FieldDTO
                  control={control}
                  inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                  type="text"
                  component={TextInput}
                  dtoObject={{
                    name: 'search',
                    type: String,
                    validators: [],
                    labelKey: 'search',
                  }}
                  className="small no-border search-field "
                />
                <FieldDTO
                  control={control}
                  type="text"
                  dtoObject={{
                    name: 'parking_zone_id',
                    type: String,
                    validators: [],
                    labelKey: 'zones',
                  }}
                  component={CustomSelectInput}
                  options={zoneOptions}
                  addPlaceholder
                  className="no-border select-field"
                />
                {/* <FieldDTO
                  control={control}
                  type="text"
                  dtoObject={{
                    name: 'group',
                    type: String,
                    validators: [],
                    labelKey: 'group',
                  }}
                  component={CustomSelectInput}
                  options={clientTypeList}
                  className="no-border select-field"
                /> */}
                {/* <SeparateBox />
                <IconBox icon="export" /> */}
                {/* <SeparateBox />
                <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} /> */}
                {/* {isOpenFilter
                  ? (
                    <HostsFilterForm
                      onHide={() => { setIsOpenFilter(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      register={register}
                      reset={reset}
                      zoneList={zoneList}
                      defaultValues={defaultFilterValues}
                      clientTypeList={clientTypeOptions}
                      meta={hostListMeta}
                    />
                  )
                  : null} */}
              </div>
            )
              : null
          }
        </div>
        { selectedTab === 1 && !selectedRow ? <HostGrid meta={hostListMeta} loadData={loadData} data={hostList} onClickByRow={handlerClickRow} /> : null }
        { selectedTab === 1 && selectedRow ? <HostStatus selectedHost={selectedRow} /> : null }
        { selectedTab === 2 ? <YandexMap /> : null }
        {/* <HostSettingPopup selectedRow={hostList[0]} /> */}
      </div>
    </div>
  );
}
