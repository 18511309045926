import React, { useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Dropdown from '../base/Dropdown/Dropdown';
import { getSystemData } from '../../modules/System/reducer';
import { setUpdatePeriod } from '../../modules/System/actions';
import { Icons } from '../base/BootstrapComponents';

export default function UpdatePeriodSelector(props) {
  const {
    disabled = false,
  } = props;
  const anchorElement = useRef(null);
  let iconName = '';
  const { updatePeriod } = useSelector(getSystemData); const intl = useIntl();
  const { formatMessage } = intl;

  const updatePeriods = [
    { value: 0, name: formatMessage({ id: 'update.period.0' }), icon: 'update-period-0' },
    { value: 1, name: formatMessage({ id: 'update.period.1' }), icon: 'update-period-60' },
    { value: 10, name: formatMessage({ id: 'update.period.10' }), icon: 'update-period-10' },
    { value: 30, name: formatMessage({ id: 'update.period.30' }), icon: 'update-period-30' },
    { value: 60, name: formatMessage({ id: 'update.period.60' }), icon: 'update-period-60' },
    { value: 300, name: formatMessage({ id: 'update.period.300' }), icon: 'update-period-300' },
  ];
  const inputOption = updatePeriods.find(i => i.value === updatePeriod);
  iconName = inputOption ? inputOption.icon : 'update-period-0';
  const dispatch = useDispatch();

  const onChange = value => {
    dispatch(setUpdatePeriod(value));
  };

  return (
    <Dropdown
      dropBottom
      usePortal
      disabled={disabled}
      className="auto-reload"
    >
      <Dropdown.Toggle className="auto-reload-toogle">
        <div ref={anchorElement} className="auto-reload-block">
          <Icons customIcon icon={iconName} className="auto-reload-icon" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="select-options-wrapper auto-reload-options-wrapper"
        anchorElement={anchorElement}
        classNamePortal="portal-select-options"
      >
        <div className="select-options-list">
          {updatePeriods.map((item, key) => (
            <div
              key={key}
              className={classNames('select-option', { selected: item.value === updatePeriod }, { updatePeriod: true })}
              onClick={() => { onChange(item.value); }}
            >
              {item.name || ''}
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
