import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import RadioButton from '../../../components/fields/RadioButton/RadioButton';
import { sessionStatusFormatter } from '../../../utils/formatters';

export default function SessionLogFilterForm(props) {
  const {
    onHide, getValues, setValue, control, reset, defaultValues, sessionMeta, sessionStatusOptions, zoneOptions, clientTypeOptions,
  } = props;
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    const a = new window.IScroll('#filter-scroll-wrapper', {
      scrollbars: true,
      mouseWheel: true,
      preventDefault: false,
      interactiveScrollbars: true,
    });
    setScroll(a);
  }, []);

  const handleRemoveParams = () => {
    const cleanUrl = window.location.pathname;
    navigate(cleanUrl, { replace: true });
  };

  const clear = () => {
    reset({ ...getValues(), ...defaultValues });
    handleRemoveParams();
  };

  const save = () => {
    localStorage.setItem('slp-filter', JSON.stringify({ ...getValues() }));
    onHide();
  };

  return (
    <Dialog
      className={classNames('full-filter')}
      onClose={save}
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Фильтры</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
      )}
      />
      <DialogContent className="hff-content">
        <div className="scroll-wrapper filter-scroll-wrapper" id="filter-scroll-wrapper">
          <div className="scroll">
            <form>
              <FieldDTO
                type="text"
                control={control}
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'session_id',
                  type: String,
                  validators: [],
                  labelKey: 'slp.sessionNumber',
                }}
              />
              <FieldDTO
                type="text"
                control={control}
                component={TextInputWithLabel}
                placeholder="Начните печатать для поиска"
                dtoObject={{
                  name: 'client_id',
                  type: String,
                  validators: [],
                  labelKey: 'slp.client_id',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'ticket_number',
                  type: String,
                  validators: [],
                  labelKey: 'slp.ticket_number',
                }}
                className="filter-margin"
              />
              <FieldDTO
                type="text"
                control={control}
                dtoObject={{
                  name: 'parking_zone_id',
                  type: String,
                  validators: [],
                  labelKey: 'slp.clientZone',
                }}
                component={SelectInput}
                options={zoneOptions}
                className="filter-margin"
                withLabel
                addPlaceholder
              />
              <FieldDTO
                control={control}
                type="text"
                dtoObject={{
                  name: 'client_type',
                  type: String,
                  validators: [],
                  labelKey: 'slp.clientType',
                }}
                withLabel
                addPlaceholder
                component={SelectInput}
                options={clientTypeOptions}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'lpn',
                  type: String,
                  validators: [],
                  labelKey: 'slp.governmentNumber',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={RadioButton}
                dtoObject={{
                  name: 'status',
                  type: Number,
                  validators: [],
                  labelKey: 'slp.sessionStatus',
                }}
                className="filter-margin"
                withLabel
                addPlaceholder
                options={sessionStatusOptions}
                formatter={sessionStatusFormatter}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <div className="hff-footer-text">Найдено {sessionMeta && sessionMeta.total ? sessionMeta.total : 0} сессий</div>
        <div className="hff-footer-buttons">
          <Button className="hff-clear-show" onClick={save}>Показать</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={clear}>Сбросить всё</Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
