export default function addFilterQueryWithCondition(filter, pool = {}) {
  const filterData = Object.entries(filter).reduce((prev, current) => {
    if (current[1]) {
      const [name, value] = current;
      prev[name] = value;
    }
    return prev;
  }, {});
  return { ...pool, ...filterData };
}
