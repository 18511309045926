import React from 'react';
import classNames from 'classnames';
import './payment-log-page.scss';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { paymentLogColumns } from '../constants';
import { copyText } from '../../../utils/utils';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';

export default function PaymentLogPopUp(props) {
  const { onHide, selectedItem } = props;
  const navigate = useNavigate();

  return (
    <Dialog
      className={classNames('plp-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            {/* Требуются уточнить у дизайнера */}
            <div className="hff-title-text f2">Информация о платеже</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <div className="plp-basic-information-box">
          {getColumnsForTable(paymentLogColumns, ['host', 'paid_at', 'id', 'amount', 'rrn', 'auth_code', 'masked_pan', 'session', 'method_label', 'type_label']).map((column, index) => (
            <div className="plp-basic-information-box-item" key={index}>
              <div className="plp-basic-information-box-item-title">{column.text}</div>
              <div className="plp-basic-information-box-item-content" onClick={column.dataField === 'session' ? () => navigate(`/session-log?session_num=${selectedItem.session.id}`) : () => {}}>{column.formatter ? column.formatter(selectedItem[column.dataField]) : selectedItem[column.dataField]}</div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Копировать" onClick={() => copyText(selectedItem, paymentLogColumns)} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
