import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import './payment-log-page.scss';
import { useSearchParams } from 'react-router-dom';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getPaymentData } from '../reducer';
import { loadPaymentList, loadPaymentMethodList, loadPaymentTypeList } from '../actions';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import PaymentLogGrid from './PaymentLogGrid';
import PaymentLogPopUp from './PaymentLogPopUp';
import PaymentLogFilterForm from './PaymentLogFilterForm';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import {
  responseLogWithNotification,
  getPaginationOpts,
  getJsonFromStorage,
  useUpdatePeriod,
} from '../../../utils/utils';
import { getZoneData } from '../../Zones/reducer';
import Loader from '../../../components/base/Loader/Loader';
import { getSystemData } from '../../System/reducer';

export default function PaymentLogPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const {
    paymentList,
    paymentListMeta,
    loadingPaymentList,
    paymentTypeList,
    paymentMethodList,
  } = useSelector(getPaymentData);

  const { zoneList, loadingZoneList } = useSelector(getZoneData);
  const { updatePeriod } = useSelector(getSystemData);
  const client_id = searchParams.get('client_id');
  const session_id = searchParams.get('session_id');

  const typePaymentOptions = paymentTypeList.map(type => ({ name: type.label, value: type.value }));
  const methodPaymentOptions = paymentMethodList.map(type => ({ name: type.label, value: type.value }));
  const zoneOptions = zoneList.map(zone => ({ name: zone.name, value: zone.id }));

  const handlerClickRow = item => {
    setSelectedRow(item);
    setIsPopup(true);
  };

  // const loadDataFromStorage = () => {
  //   const storage = getJsonFromStorage('payment-columns-filter', null);
  //   return storage ? storage.map(column => {
  //     const search = sessionLogColumn.find(e => e.dataField === column.name);
  //     return { ...search, ...column };
  //   }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  // };
  const defaultFilterValues = {
    search: '',
    paymentMethod: '',
    paymentType: '',
    parking_zone_id: '',
    client_id: '',
    session_id: '',
    host_name: '',
    paid_at: '',
    amount: '',
    method: '',
    type: '',
    masked_pan: '',
    rrn: '',
    auth_code: '',
  };

  const initial = getJsonFromStorage('plp-filter', defaultFilterValues);
  const {
    register, getValues, watch, setValue, reset, control,
  } = useForm({ defaultValues: { ...initial, client_id: client_id || initial.client_id, session_id: session_id || initial.session_id } });

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(paymentListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const p1 = dispatch(loadPaymentList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };
  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => { loadData(); });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(loadPaymentMethodList()).catch(responseLogWithNotification);
    dispatch(loadPaymentTypeList()).catch(responseLogWithNotification);
  }, []);

  return (
    <div className="payment-log-page">
      <div className="plp-container">
        <div className="plp-inputs-box">
          <PageHeader header="Журнал платежей">
            <div className="header-loader"><Loader loading={loadingZoneList || loadingPaymentList} /></div>
          </PageHeader>
          <div className="plp-inputs">
            <FieldDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              control={control}
              component={TextInput}
              dtoObject={{
                name: 'search',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              className="small no-border search-field "
            />
            <FieldDTO
              type="text"
              component={TextInput}
              control={control}
              placeholder="Номер сессии"
              dtoObject={{
                name: 'session_id',
                type: String,
                validators: [],
                labelKey: 'plp.session_id',
              }}
              className="small no-border"
            />
            {/* <FieldDTO
              type="text"
              dtoObject={{
                name: 'parking_zone_id',
                type: String,
                validators: [],
                labelKey: 'plp.clientZone',
              }}
              inputProps={{
                ...register('parking_zone_id', { maxLength: 30 }),
                onChange: e => {
                  setValue('parking_zone_id', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
                value: getValues('parking_zone_id') || '',
              }}
              placeholder="Зона клиента"
              component={CustomSelectInput}
              options={zoneOptions}
              className="select-field no-border"
            /> */}
            {/* <SeparateBox />
            <IconBox icon="archive" /> */}

            <SeparateBox />
            <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
            {isOpenFilter
              ? (
                <PaymentLogFilterForm
                  onHide={() => { setIsOpenFilter(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  control={control}
                  reset={reset}
                  paymentListMeta={paymentListMeta}
                  defaultValues={defaultFilterValues}
                  typePaymentOptions={typePaymentOptions}
                  methodPaymentOptions={methodPaymentOptions}
                />
              )
              : null}
          </div>
        </div>
        <PaymentLogGrid data={paymentList} meta={paymentListMeta} loadData={loadData} onClickByRow={handlerClickRow} />
        { isPopup ? <PaymentLogPopUp onHide={() => setIsPopup(false)} selectedItem={selectedRow} /> : null }
      </div>
    </div>
  );
}
