import { handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import {
  CLIENT_CHECK,
  CLIENT_LOGIN,
  CLIENT_LOGOUT,
  CLIENT_REGISTRATION,
  FORGOT_PASSWORD,
} from './actions';
import {
  LOCAL_STORAGE_CLIENT_SESSION_KEY,
  LOCAL_STORAGE_CLIENT_SESSION_TIMEOUT,
} from '../../configs/names';
import { FAILED, SUCCESSFUL } from '../../configs/actionNames';
import { CLIENT_MODULE } from '../../configs/moduleNames';
import { createOperationStateReducer } from '../../utils/getCrudConfig';
import { MODULE_NAME } from '../Role/constants';
import UserDTO from '../../dto/UserDTO';

const getToken = () => {
  try {
    return window.localStorage.getItem(LOCAL_STORAGE_CLIENT_SESSION_KEY) || null;
  } catch (e) {
    return null;
  }
};

const setStorageClient = token => {
  window.localStorage.setItem(LOCAL_STORAGE_CLIENT_SESSION_KEY, token);
};
const clearStorageClient = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_CLIENT_SESSION_KEY);
};

const getUser = () => {
  try {
    const result = window.localStorage.getItem('clientData');
    return result ? JSON.parse(result) : {};
  } catch (e) {
    return {};
  }
};

const token = getToken() || null;
// if (!client.id || !token) {
if (!token) {
  clearStorageClient();
}

const initialState = {
  loggedIn: Boolean(token),
  // loggedIn: true,
  loginProcess: false,
  clientData: getUser(),
  token,
  loadingForgotPassword: false,
};

const reducer = handleActions({
  ...createOperationStateReducer(MODULE_NAME + FORGOT_PASSWORD, 'loadingForgotPassword'),
  [CLIENT_MODULE + CLIENT_LOGIN]: state => {
    clearStorageClient();
    return {
      ...state,
      token: null,
      clientData: {},
      loggedIn: false,
      loginProcess: true,
    };
  },
  [CLIENT_MODULE + CLIENT_LOGIN + SUCCESSFUL]: (state, action) => {
    const { token, user } = action.payload;
    setStorageClient(token);
    window.localStorage.setItem('clientData', JSON.stringify(user));
    window.localStorage.setItem(LOCAL_STORAGE_CLIENT_SESSION_TIMEOUT, new Date().getTime());
    return {
      ...state,
      token,
      // loggedIn: !!token,
      clientData: user,
      loginProcess: false,
    };
  },
  [CLIENT_MODULE + CLIENT_LOGIN + FAILED]: state => {
    clearStorageClient();
    return {
      ...state,
      clientData: {},
      token: null,
      loggedIn: false,
      loginProcess: false,
      loadingClientCheck: false,
    };
  },
  [CLIENT_MODULE + CLIENT_CHECK]: (state, action) => ({
    ...state,
    loadingClientCheck: true,
  }),
  [CLIENT_MODULE + CLIENT_CHECK + SUCCESSFUL]: (state, action) => ({
    ...state,
    clientData: action.payload,
    loggedIn: true,
    loadingClientCheck: false,
  }),
  [CLIENT_MODULE + CLIENT_CHECK + FAILED]: (state, action) => ({
    ...state,
    clientData: action.payload,
    loggedIn: true,
    loadingClientCheck: false,
  }),
  [CLIENT_MODULE + CLIENT_LOGOUT + SUCCESSFUL]: state => {
    clearStorageClient();
    return {
      ...state,
      clientData: {},
      token: null,
      loggedIn: false,
    };
  },
  [CLIENT_MODULE + CLIENT_REGISTRATION]: state => ({
    ...state,
    loggedIn: false,
  }),
}, initialState);

export default reducer;

export const getClientPersonalData = createSelector(
  [state => state[CLIENT_MODULE]],
  state => new UserDTO(state.clientData),
);

// todo create Client DTO
export const getClientData = createSelector(
  [state => state[CLIENT_MODULE]],
  state => state,
);
