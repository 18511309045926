import { handleActions } from 'redux-actions';

import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { EVENT_LOG_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_EVENT_LIST, LOAD_EVENT_STATUS_LIST,
} from './actions';

const initialState = {
  loadingEventList: false,
  loadEventStatusList: false,
  eventList: [],
  eventListMeta: [],
  eventStatusList: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST, 'eventList', 'loadingEventList'),
  ...createLoadListReducerWithBegin(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST, 'eventStatusList', 'loadEventStatusList'),
}, initialState);

export default reducer;

export const getEventData = createSelector(
  [state => state[EVENT_LOG_PAGE_MODULE]],
  state => {
    const {
      eventList,
      eventListMeta,
      eventStatusList,
      loadingEventList,
      loadEventStatusList,
    } = state;

    return {
      eventList,
      eventStatusList,
      eventListMeta,
      loadingEventList,
      loadEventStatusList,
    };
  },
);
