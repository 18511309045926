import { put, call, delay } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL, DEFAULT_ITEMS_OF_PAGE, LOADING_DELAY } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadSessionListFailed,
  loadSessionListSuccessful,
  loadSessionListBegin,
} from '../actions';
import addFilterQueryWithCondition from '../../../utils/addFilterQueryWithCondition';

export default function* loadSessionList(action) {
  try {
    const { payload = {} } = action;
    const {
      limit = DEFAULT_ITEMS_OF_PAGE,
      page = 1,
      filter,
    } = payload;
    let pool = {
      limit,
      page,
      with_parking_zone: 1,
      with_client: 1,
      with_session: 1,
    };
    if (typeof filter === 'object' && Object.keys(filter).length) {
      yield delay(LOADING_DELAY);
      pool = addFilterQueryWithCondition(filter, pool);
    }
    const url = `${BACKEND_URL}/sessions`;
    yield put(loadSessionListBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadSessionListSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadSessionListFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadSessionListFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
