import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN, FAILED, SUCCESSFUL, CREATE, DELETE_BY_ID, LOAD_BY_ID,
} from '../../configs/actionNames';
import { HOSTS_MODULE } from '../../configs/moduleNames';

export const LOAD_HOST_LIST = '_LOAD_HOST_LIST';
export const CREATE_HOST = '_CREATE_HOST';
export const UPDATE_HOST = '_UPDATE_HOST';
export const LOAD_ACTION_HISTORY = '_LOAD_ACTION_HISTORY';
export const LOAD_HOST_ACTIVITIES = '_LOAD_HOST_ACTIVITIES';
export const LOAD_HOST_TYPE = '_LOAD_HOST_TYPE';
export const LOAD_HOST_COMPONENTS_TYPE = '_LOAD_HOST_COMPONENTS_TYPE';

export const createComand = createDeferredAction(HOSTS_MODULE + CREATE);
export const createComandSuccessful = createAction(HOSTS_MODULE + CREATE + SUCCESSFUL);
export const createComandFailed = createAction(HOSTS_MODULE + CREATE + FAILED);

export const deleteHost = createDeferredAction(HOSTS_MODULE + DELETE_BY_ID);
export const deleteHostSuccessful = createAction(HOSTS_MODULE + DELETE_BY_ID + SUCCESSFUL);
export const deleteHostFailed = createAction(HOSTS_MODULE + DELETE_BY_ID + FAILED);

export const createHost = createDeferredAction(HOSTS_MODULE + CREATE_HOST);
export const createHostSuccessful = createAction(HOSTS_MODULE + CREATE_HOST + SUCCESSFUL);
export const createHostFailed = createAction(HOSTS_MODULE + CREATE_HOST + FAILED);

export const loadHostTypeList = createDeferredAction(HOSTS_MODULE + LOAD_HOST_TYPE);
export const loadHostTypeListSuccessful = createAction(HOSTS_MODULE + LOAD_HOST_TYPE + SUCCESSFUL);
export const loadHostTypeListBegin = createAction(HOSTS_MODULE + LOAD_HOST_TYPE + BEGIN);
export const loadHostTypeListFailed = createAction(HOSTS_MODULE + LOAD_HOST_TYPE + FAILED);

export const loadHostList = createDeferredAction(HOSTS_MODULE + LOAD_HOST_LIST);
export const loadHostListBegin = createAction(HOSTS_MODULE + LOAD_HOST_LIST + BEGIN);
export const loadHostListSuccessful = createAction(HOSTS_MODULE + LOAD_HOST_LIST + SUCCESSFUL);
export const loadHostListFailed = createAction(HOSTS_MODULE + LOAD_HOST_LIST + FAILED);

export const loadActionHistory = createDeferredAction(HOSTS_MODULE + LOAD_ACTION_HISTORY);
export const loadActionHistoryBegin = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + BEGIN);
export const loadActionHistorySuccessful = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + SUCCESSFUL);
export const loadActionHistoryFailed = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + FAILED);

export const loadHostComponentsType = createDeferredAction(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE);
export const loadHostComponentsTypeBegin = createAction(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE + BEGIN);
export const loadHostComponentsTypeSuccessful = createAction(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE + SUCCESSFUL);
export const loadHostComponentsTypeFailed = createAction(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE + FAILED);

export const loadHostActivities = createDeferredAction(HOSTS_MODULE + LOAD_HOST_ACTIVITIES);
export const loadHostActivitiesBegin = createAction(HOSTS_MODULE + LOAD_HOST_ACTIVITIES + BEGIN);
export const loadHostActivitiesSuccessful = createAction(HOSTS_MODULE + LOAD_HOST_ACTIVITIES + SUCCESSFUL);
export const loadHostActivitiesFailed = createAction(HOSTS_MODULE + LOAD_HOST_ACTIVITIES + FAILED);

export const loadHostById = createDeferredAction(HOSTS_MODULE + LOAD_BY_ID);
export const loadHostByIdSuccessful = createAction(HOSTS_MODULE + LOAD_BY_ID + SUCCESSFUL);
export const loadHostByIdFailed = createAction(HOSTS_MODULE + LOAD_BY_ID + FAILED);

export const updateHost = createDeferredAction(HOSTS_MODULE + UPDATE_HOST);
export const updateHostSuccessful = createAction(HOSTS_MODULE + UPDATE_HOST + SUCCESSFUL);
export const updateHostFailed = createAction(HOSTS_MODULE + UPDATE_HOST + FAILED);
