export const ONLY_AUTORIZED = true;
export const DEFAULT_LOCALE = 'ru_RU';
export const DEFAULT_LOCALE_SHORT = 'ru';
// export const DEFAULT_LOCALE_SHORT = 'en';
export const DASHBOARD_PAGE = '/dashboard/';
export const HIDE_SYSTEM_ROUTE = true;

const defaultDomainName = process.env.NODE_ENV === 'production' && window.APP_DOMAIN_NAME ? window.APP_DOMAIN_NAME : (process.env.REACT_APP_DOMAIN_NAME || '0.0.0.0');
const defaultPort = process.env.NODE_ENV === 'production' && window.APP_DOMAIN_PORT ? window.APP_DOMAIN_PORT : (process.env.REACT_APP_DOMAIN_PORT || 8080);
const defaultProtocol = process.env.NODE_ENV === 'production' && window.APP_DOMAIN_PROTOCOL ? window.APP_DOMAIN_PROTOCOL : (process.env.REACT_APP_DOMAIN_PROTOCOL || 'http');
const entryPoint = process.env.NODE_ENV === 'production' && window.APP_ENTRY_POINT ? window.APP_ENTRY_POINT : (process.env.REACT_APP_ENTRY_POINT || '');
const defaultUrl = `${defaultProtocol}://${defaultDomainName}:${defaultPort}`;
const buildVersion = process.env.NODE_ENV === 'production' && window.APP_BUILD_VERSION ? window.APP_BUILD_VERSION : (process.env.REACT_APP_BUILD_VERSION || '1.0.0.0.0');

export const BUILD_VERSION = buildVersion;
export const DEFAULT_URL = defaultUrl;
// export const BACKEND_URL = `${defaultProtocol}://ultra.fast.its-profit.ru:${defaultPort}${entryPoint}`; // defaultUrl + entryPoint;
export const BACKEND_URL = defaultUrl + entryPoint;
export const DOMAIN_NAME = defaultDomainName;
export const DOMAIN_PORT = defaultPort;
export const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN || '';
export const MAP_CENTER = JSON.parse(process.env.REACT_APP_MAP_CENTER) || [];
export const YANDEX_MAP_TOKEN = '8522c61a-195a-47a3-a6de-bece624089c0';

export const ITEMS_OF_PAGE = [{ value: 10, name: 10 }, { value: 25, name: 25 }, { value: 50, name: 50 }];
// export const DEFAULT_ITEMS_OF_PAGE = 25;
export const DEFAULT_ITEMS_OF_PAGE = 10;
// export const DEFAULT_ITEMS_OF_PAGE = 1;

export const LOADING_DELAY = 500;

export const DEFAULT_DATE_RANGE_SUBTRACT_COUNT = 1;
export const DEFAULT_DATE_RANGE_SUBTRACT_TYPE = 'month';

export const DEFAULT_CHART_COLORS = [
  '#6AD387',
  '#FFBC59',
  '#E96363',
  '#1665A7',
  '#44f0c2',
  '#6b0392',
  '#d17905',
  '#453d3f',
  '#f05b4f',
  '#dda458',
  '#eacf7d',
  '#86797d',
  '#b2c326',
  '#6188e2',
  '#a748ca',
];

// size in byte
export const MAX_UPLOAD_FILE_SIZE = 2 * 1024 * 1024;
export const MAX_UPLOAD_FILE_COUNT = 20;
export const CUSTOMER = process.env.REACT_APP_CUSTOMER || '';
export const CUSTOMER_CITY = process.env.REACT_APP_CUSTOMER_CITY || '';
export const CUSTOMER_PHONE = process.env.REACT_APP_CUSTOMER_PHONE || '';
export const CUSTOMER_EMAIL = process.env.REACT_APP_CUSTOMER_EMAIL || '';
export const SUPPORT_PHONE = process.env.REACT_APP_SUPPORT_PHONE || '';
export const SUPPORT_MAIL = process.env.REACT_APP_SUPPORT_MAIL || '';
