import React from 'react';
import defaultCarImg from '../../../theme/img/default-car.png';
import {
  dateOrDateTimeFormatter,
  vehicleRegistrationPlateFormatter,
} from '../../../utils/formatters';

export default function ClientCardInfo(props) {
  const { selectedItem } = props;
  return (
    <div className="client-card-info">
      <img className="client-card-info-img" alt="default" src={`${selectedItem.img || defaultCarImg}`} />
      {/* <div className="client-card-info-item gov">
        <div className="client-card-info-item-title">Номер ГРЗ:</div>
        <div className="client-card-info-item-data"> {vehicleRegistrationPlateFormatter(selectedItem.vehicleRegistrationPlate)} </div>
      </div> */}
      <div className="client-card-info-item">
        <div className="client-card-info-item-title">Начало:</div>
        <div className="client-card-info-item-data"> {dateOrDateTimeFormatter(selectedItem.issue_at, false, true)} </div>
      </div>
      <div className="client-card-info-item">
        <div className="client-card-info-item-title">Окончание:</div>
        <div className="client-card-info-item-data"> {dateOrDateTimeFormatter(selectedItem.expiry_at, false, true)} </div>
      </div>
      {/* <div className="client-card-info-item">
        <div className="client-card-info-item-title">Номер карты:</div>
        <div className="client-card-info-item-data"> {selectedItem.masked_pan} </div>
      </div> */}
    </div>
  );
}
