import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import Grid from '../../../components/base/Grid/Grid';
import RangeDatePicker from '../../../components/fields/RangeDatePicker/RangeDatePicker';
import getDefaultDataRange from '../../../utils/getDefaultDataRange';
import Pagination from '../../../components/base/Pagination/Pagination';
import { dateOrDateTimeFormatter, historyStatusFormatter } from '../../../utils/formatters';
import { getHostData } from '../reducer';
import { getSystemData } from '../../System/reducer';
import { getPaginationOpts, responseLogWithNotification, useUpdatePeriod } from '../../../utils/utils';
import { loadActionHistory } from '../actions';

export default function HostHistoryPopUp(props) {
  const momentRangeObject = getDefaultDataRange(1, 'month');
  const { onHide, selectedHost } = props;
  const { hostActionHistory, hostActionHistoryMeta } = useSelector(getHostData);
  const { updatePeriod } = useSelector(getSystemData);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState([]);

  const handleChangeDate = value => {
    const from = moment(value[0].toDate()).startOf('day').toISOString();
    const to = moment(value[1].toDate()).endOf('day').toISOString();
    setDateFilter([from, to]);
  };

  const loadData = opts => {
    const paginationOpts = getPaginationOpts(hostActionHistoryMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { host_id: selectedHost.id },
    };
    if (dateFilter && dateFilter.length) {
      const [a, b] = dateFilter;
      requestData.filter.created_at_from = a;
      requestData.filter.created_at_to = b;
    }
    return dispatch(loadActionHistory(requestData)).catch(responseLogWithNotification);
  };

  const [setPeriod, destroy] = useUpdatePeriod(() => {
    const p1 = loadData();
    return Promise.all([p1]);
  }, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);
  useEffect(() => {
    loadData();
    return destroy;
  }, [dateFilter]);

  const hostStatusColumn = [
    {
      dataField: 'created_at',
      text: 'Дата и время',
      disableSort: true,
      hidden: true,
      dataClassName: 'align-middle',
      formatter: date => dateOrDateTimeFormatter(date),
      headerClassName: 'column-0',
    },
    {
      dataField: 'command_label',
      text: 'Команда',
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-1',
      hidden: true,
    },
    {
      dataField: 'status',
      text: 'Статус команды',
      disableSort: true,
      dataClassName: 'align-middle',
      formatter: historyStatusFormatter,
      headerClassName: 'column-2',
      hidden: true,
    },
  ];
  return (
    <Dialog
      className={classNames('host-status-event-grid-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">История команд хоста {selectedHost.name}</div>
            <RangeDatePicker
              value={momentRangeObject}
              hideSmallButtons
              usePortal
              hidePickerIcon
              showArrowIcon
              className="no-border"
              handleChangeDate={handleChangeDate}
            />
          </>
        )}
      />
      <DialogContent className="hff-content">
        <div className="host-table g-0 setting-table">
          <div className="frame">
            <div className="sticky-table-wrapper custom-scroll-theme">
              <Grid
                className="host-grid"
                columns={hostStatusColumn}
                data={hostActionHistory}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <Pagination list={hostActionHistory} meta={hostActionHistoryMeta} handleChangeMeta={loadData} />
      </DialogFooter>
    </Dialog>
  );
}
