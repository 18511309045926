import React, { useState } from 'react';
import classNames from 'classnames';
import Pagination from '../../../components/base/Pagination/Pagination';
import { Icons } from '../../../components/base/BootstrapComponents';

import {
  EquipmentFormatter,
  MoreFormatter,
  controlBoxFormatter,
  HostNameAndStatusFormatter,
  hostStatusFormatter,
} from '../../../utils/formatters';
import CheckBoxInput from '../../../components/fields/CheckBoxInput/CheckBoxInput';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';

export default function HostsDirectoryGrid(props) {
  const defaultColumns = [
    {
      dataField: 'checkBox',
      disableSort: true,
      text: 'Выбор строки',
      hidden: true,
      headerClassName: 'column-0 text-center column-relative',
      dataClassName: 'align-middle column-relative',
    },
    {
      dataField: 'host',
      text: 'Объект',
      formatter: (_, a) => (<HostNameAndStatusFormatter item={a} />),
      disableSort: true,
      hidden: true,
      headerClassName: 'column-1',
    },
    {
      dataField: 'id',
      text: 'Идентификатор',
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-2',
      hidden: true,
    },
    {
      dataField: 'code',
      text: 'Серийный номер',
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-3',
      hidden: true,
    },
    // {
    //   dataField: 'status',
    //   text: 'Статус',
    //   // formatter: item => hostStatusFormatter(item),
    //   disableSort: true,
    //   dataClassName: 'align-middle',
    //   headerClassName: 'align-middle',
    //   hidden: true,
    // },
    {
      dataField: 'active_components',
      text: 'Периферийные модули, ПО',
      formatter: EquipmentFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-4',
      hidden: true,
    },
    {
      dataField: 'available_actions',
      text: 'Доступные действия',
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'align-middle column-5',
      hidden: true,
    },
  ];

  const {
    data,
    loadData,
    onClickByRow,
    setSelected,
    setMode,
    selected,
    meta,
    columns = defaultColumns,
  } = props;

  const checkBoxColumn = defaultColumns.find(column => column.dataField === 'checkBox');

  const control = defaultColumns.find(column => column.dataField === 'available_actions');

  if (control) {
    control.formatter = (_, item) => (
      <div className="control-box-formatter">
        <div className="control-box-button" key="trash-edit-index">
          <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
          <div className="expanded-click-zone" onClick={e => { setMode('edit'); }} />
        </div>
        <div className="control-box-button" key="trash-active-index">
          <Icons width={24} height={24} customIcon icon="trash-active" className="button-icons" />
          <div className="expanded-click-zone" onClick={e => { setMode('delete'); setSelected([item.id]); e.stopPropagation(); }} />
        </div>
      </div>
    );
  }

  if (checkBoxColumn) {
    checkBoxColumn.formatter = (_, item) => {
      const check = selected.includes(item.id);
      return (
        <div className="check-box-field-formatter" onClick={e => { setSelected(check ? selected.filter(e => e !== item.id) : [...selected, item.id]); e.stopPropagation(); }}>
          <CheckBoxInput
            inputProps={{
              value: check,
            }}
          />
        </div>
      );
    };

    checkBoxColumn.headerFormatter = () => {
      const isAll = selected.length === data.length && selected.length && data.length;
      return (
        <div className="check-box-field-formatter" onClick={() => setSelected(selected.length < data.length ? data.map(e => e.id) : [])}>
          <CheckBoxInput
            className={classNames({ 'some-child': selected.length })}
            inputProps={{
              value: isAll,
            }}
          />
        </div>
      );
    };
  }

  return (
    <div className="host-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="host-grid" isSettingColumn={false} data={data} columns={columns} name="session-list" onClickByRow={onClickByRow} settingsFormatter={MoreFormatter} />
        </div>
      </div>
      <Pagination meta={meta} list={data} handleChangeMeta={loadData} />
    </div>
  );
}
