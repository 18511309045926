import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { SERVICE_MONITORING_MODULE } from '../../configs/moduleNames';

export const LOAD_MONITOR_LIST = '_LOAD_MONITOR_LIST';

export const loadMonitorList = createDeferredAction(SERVICE_MONITORING_MODULE + LOAD_MONITOR_LIST);
export const loadMonitorListBegin = createAction(SERVICE_MONITORING_MODULE + LOAD_MONITOR_LIST + BEGIN);
export const loadMonitorListSuccessful = createAction(SERVICE_MONITORING_MODULE + LOAD_MONITOR_LIST + SUCCESSFUL);
export const loadMonitorListFailed = createAction(SERVICE_MONITORING_MODULE + LOAD_MONITOR_LIST + FAILED);
