import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import './hosts-directory-page.scss';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';

export default function HostEditPopup(props) {
  const {
    onHide,
    setComponents,
    components,
    update,
    selectedRow,
    hostTypeOptions,
  } = props;

  const {
    control, getValues,
  } = useForm({ defaultValues: { name: selectedRow.name, code: selectedRow.code, type: selectedRow.type } });

  const toogleComponent = item => {
    const isExit = components.filter(e => e.type === item.type).length;
    if (isExit) {
      setComponents(components.filter(e => e.type !== item.type));
    } else {
      setComponents([...components, item]);
    }
  };

  useEffect(() => {
    setComponents(selectedRow.components.filter(e => e.is_active));
  }, []);

  return (
    <Dialog
      className={classNames('host-popup-add')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">
              Редактирование хоста
            </div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content custom-scroll-theme">
        <div className="input-form">
          <FieldDTO
            type="text"
            component={TextInputWithLabel}
            placeholder="Введите имя хоста"
            dtoObject={{
              name: 'name',
              type: String,
              validators: [],
              labelKey: 'host.hostName',
            }}
            className="short"
            control={control}
          />
          <FieldDTO
            control={control}
            type="text"
            withLabel
            dtoObject={{
              name: 'type',
              type: String,
              validators: [],
              labelKey: 'host.type',
            }}
            addPlaceholder
            component={CustomSelectInput}
            options={hostTypeOptions}
            className="select-field no-border short"
          />
          <FieldDTO
            type="text"
            component={TextInputWithLabel}
            placeholder="Введите серийный номер"
            dtoObject={{
              name: 'code',
              type: String,
              validators: [],
              labelKey: 'host.id',
            }}
            control={control}
          />
        </div>
        <div className="form-equpment">
          <div className="form-description">
            <span className="form-description-title">Все оборудование</span>
            <div>Чтобы добавить оборудование к хосту, нажмите на него. Чтобы убрать оборудование нажмите на него повторно. </div>
          </div>
          <div className="equipments-group">
            {selectedRow && selectedRow.components.map(item => (
              <div className="equipments-group-item" key={item.id} onClick={() => toogleComponent(item)}>
                <Icons width={28} height={28} customIcon icon={item.type} className="button-icons" />
                <div className="equipments-group-items-title">{item.type_label}</div>
                <div className={classNames('item-circle', { isSelected: components.filter(e => e.type === item.type).length })}>
                  <Icons width={20} height={20} customIcon icon="successful" className="button-icons" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>

      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={() => { update(selectedRow.id, { ...getValues(), active_component_ids: components.map(e => e.id) }); onHide(); setComponents([]); }} />
        <CustomButton isCancel text="Отменить" onClick={() => { onHide(); setComponents([]); }} />
      </DialogFooter>
    </Dialog>
  );
}
