import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { getSessionData } from '../reducer';
import './session-log-page.scss';
import { sessionLogColumns } from '../constants';
import { Icons } from '../../../components/base/BootstrapComponents';
import { loadSessionList, loadSessionId, loadSessionStatusList } from '../actions';
import {
  responseLogWithNotification, getPaginationOpts, copyText, getJsonFromStorage, useUpdatePeriod,
} from '../../../utils/utils';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import SessionLogGrid from './SessionLogGrid';
import SessionLogFilterForm from './SessionLogFilterForm';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import SessionLogStatus from './SessionLogStatus';
import { getZoneData } from '../../Zones/reducer';
import Loader from '../../../components/base/Loader/Loader';
import { getClientData } from '../../Clients/reducer';
import { getSystemData } from '../../System/reducer';
import { loadZoneList } from '../../Zones/actions';
import { loadClientTypeList } from '../../Clients/actions';

export default function SessionLogPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const { updatePeriod } = useSelector(getSystemData);
  const {
    sessionList,
    sessionListMeta,
    sessionStatusList,
  } = useSelector(getSessionData);
  const { clientTypeList } = useSelector(getClientData);
  const { zoneList, loadingZoneList } = useSelector(getZoneData);
  const [searchParams, setSearchParams] = useSearchParams();
  const session_num = searchParams.get('session_num');
  const client_id = searchParams.get('client_id');

  const handlerClickRow = item => {
    setSelectedRow(item);
    setSearchParams({ session_id: item.id });
  };

  const clearParams = () => {
    const { pathname } = window.location;
    navigate(pathname, { replace: true });
  };

  const sessionStatusOptions = sessionStatusList.map(type => ({ name: type.label, value: type.value }));
  const zoneOptions = zoneList.map(zone => ({ name: zone.name, value: zone.id }));
  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));

  const defaultFilterValues = {
    search: '',
    session_id: '',
    client_id: '',
    parking_zone_id: '',
    client_type: '',
    started_at_from: '',
    started_at_to: '',
    status: '',
    lpn: '',
    total: '',
    ticket_number: '',
  };
  const initial = getJsonFromStorage('slp-filter', defaultFilterValues);
  const {
    control, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: { ...initial, client_id: client_id || initial.client_id } });

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(sessionListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const p1 = dispatch(loadSessionList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => {
      loadData();
      localStorage.setItem('slp-filter', JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (session_num) {
      dispatch(loadSessionId({ id: session_num })).then(result => setSelectedRow(result.data)).catch(responseLogWithNotification);
    }
  }, [session_num]);

  useEffect(() => {
    dispatch(loadZoneList()).catch(responseLogWithNotification);
    dispatch(loadClientTypeList()).catch(responseLogWithNotification);
    dispatch(loadSessionStatusList()).catch(responseLogWithNotification);
  }, []);

  return (
    <div className="session-log-page">
      <div className="slp-container">
        <div className="slp-inputs-box">
          <div className={classNames('slp-title-page', { variant: selectedRow })}>
            {
                selectedRow ? (
                  <div className="path">
                    <span className="path-main" onClick={() => { setSelectedRow(null); clearParams(); }}>Журнал сессий</span> / <span>Статус сессии</span>
                  </div>
                ) : null
              }
            <PageHeader header={selectedRow ? `Статус сессии ${selectedRow.id}` : 'Журнал сессий'}>
              <div className="header-loader"><Loader loading={loadingZoneList} /></div>
            </PageHeader>
          </div>
          {
            !selectedRow ? (
              <div className="slp-inputs">
                <FieldDTO
                  inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                  type="text"
                  control={control}
                  component={TextInput}
                  dtoObject={{
                    name: 'search',
                    type: String,
                    validators: [],
                    labelKey: 'search',
                  }}
                  placeholder="Поиск"
                  className="small no-border search-field "
                />
                <FieldDTO
                  type="text"
                  control={control}
                  component={TextInput}
                  dtoObject={{
                    name: 'session_id',
                    type: String,
                    validators: [],
                    labelKey: 'slp.sessionNumber',
                  }}
                  addPlaceholder
                  className="small no-border"
                />
                <FieldDTO
                  type="text"
                  control={control}
                  dtoObject={{
                    name: 'parking_zone_id',
                    type: String,
                    validators: [],
                    labelKey: 'slp.clientZone',
                  }}
                  addPlaceholder
                  component={CustomSelectInput}
                  options={zoneOptions}
                  className="select-field no-border"
                />
                <FieldDTO
                  type="text"
                  control={control}
                  dtoObject={{
                    name: 'status',
                    type: String,
                    validators: [],
                    labelKey: 'slp.sessionStatus',
                  }}
                  addPlaceholder
                  component={CustomSelectInput}
                  options={sessionStatusOptions}
                  className="select-field no-border"
                />
                {/* <SeparateBox />
                <IconBox icon="export" /> */}
                {/* <SeparateBox />
                <IconBox icon="archive" /> */}
                <SeparateBox />
                <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
                {isOpenFilter
                  ? (
                    <SessionLogFilterForm
                      onHide={() => { setIsOpenFilter(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      sessionMeta={sessionListMeta}
                      reset={reset}
                      control={control}
                      defaultValues={defaultFilterValues}
                      sessionStatusOptions={sessionStatusOptions}
                      zoneOptions={zoneOptions}
                      clientTypeOptions={clientTypeOptions}
                    />
                  )
                  : null}
              </div>
            )
              : (
                <div className="slp-inputs">
                  <IconBox icon="document" onClick={() => copyText(selectedRow, sessionLogColumns)} />
                  {/* <SeparateBox />
                  <IconBox icon="export" /> */}
                  {/* <SeparateBox />
                  <CustomButton text="Заблокировать" icon="lock-default" /> */}
                </div>
              )
          }
        </div>
        { !selectedRow ? <SessionLogGrid data={sessionList} meta={sessionListMeta} loadData={loadData} onClickByRow={handlerClickRow} /> : null}
        { selectedRow ? <SessionLogStatus selectedItem={selectedRow} /> : null }
      </div>
    </div>
  );
}
