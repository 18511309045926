import React from 'react';
import classNames from 'classnames';
import renderMessage from '../renderMessage';
import './SwitchesStyles.scss';

export default function Switches(props) {
  const {
    label,
    className = '',
    inputClassName = '',
    textMuted = '',
    disabled = false,
    largeControl = false,
    withLabel = true,
    inputProps = {},
    multiple = false,
    inverse = false,
    size = 1,
    fieldState = {
      error: '',
      touched: false,
    },
    controlId = `text-input-${Math.random() * 10000}`,
    defaultChecked = false,
  } = props;
  const {
    error,
    isTouched: touched,
  } = fieldState;
  const onChange = event => {
    inputProps.onChange({ target: { value: event.target.checked, name: inputProps.name } });
    return false;
  };
  const isError = Boolean(error);
  return (
    <div className={classNames('form-control-wrapper switch-control-wrapper', className)}>
      <div className={classNames('form-switch', { 'is-invalid': isError && touched })}>
        {withLabel ? <label htmlFor={controlId} className="form-switch-label">{renderMessage(label)}</label> : null}
        <input
          className={classNames('form-switch-input', inputClassName, { 'form-control-lg': largeControl }, { 'is-invalid': isError && touched }, { inverse })}
          type="checkbox"
          id={controlId}
          disabled={disabled}
          {...inputProps}
          multiple={multiple}
          size={size}
          checked={inputProps ? inputProps.value : defaultChecked}
          onChange={onChange}
        />
      </div>
      {textMuted ? <div id={`${controlId}-help`} className="form-text">{renderMessage(textMuted)}</div> : null}
      {touched && isError ? <div className="invalid-feedback">{renderMessage(error)}</div> : null}
    </div>
  );
}
