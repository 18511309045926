import React from 'react';
import classNames from 'classnames';
import './vehicle-registration-plate.scss';
import { regNumEnToRu } from '../../../utils/utils';

export default function VehicleRegistrationPlate(props) {
  const { item = {} } = props;
  const lpn = item || {};
  const {
    lpn_type = 'ru',
    vehicle_type = 'car',
    lpn_parsed = '',
  } = lpn;

  if (!lpn_parsed) return null;

  const lpnRegionParts = lpn_parsed.split(' ');
  const lpnNumber = lpnRegionParts && lpnRegionParts.length ? String(lpnRegionParts[0]) : '';
  const digit = lpnNumber.replace(/\D*/ig, '');
  const lpnParts = lpnNumber.split(digit);
  const number = digit || lpn_parsed;
  const char = lpnParts && lpnParts[0] ? lpnParts[0] : '';
  const charSecond = lpnParts && lpnParts[1] ? lpnParts[1] : '';
  const region = lpnRegionParts && lpnRegionParts.length ? String(lpnRegionParts[lpnRegionParts.length - 1]) : '';
  const country = lpn_type && String(lpn_type).includes('ru') ? 'RUS' : String(lpn_type).toUpperCase();
  const isLongRegion = region.toString().length === 3;
  const isMoto = vehicle_type === 'motorcycle';

  const renderFlag = () => (
    <div className="gov-number-region-flag">
      <div className="strip" />
      <div className="strip" />
      <div className="strip" />
    </div>
  );

  return (
    <div className={classNames('gov-number', lpn_type)}>
      <div className={classNames('gov-number-container', { long: isLongRegion })}>
        <div className="gov-number-number">
          <div className="gov-number-number-container">
            { char ? <div className="gov-number-number-char">  {regNumEnToRu(char)} </div> : null }
            <div className="gov-number-number-int">  {number} </div>
            { charSecond && !isMoto ? <div className="gov-number-number-char">  {regNumEnToRu(charSecond)} </div> : null }
          </div>
        </div>
        <div className="gov-number-region">
          { isMoto ? <div className="gov-number-number-char">  {regNumEnToRu(charSecond)} </div> : null }
          <div className="gov-number-region-number">
            {region}
            <div className="gov-number-region-container">
              <div className="gov-number-region-name">
                {country}
              </div>
              {country === 'RUS' ? renderFlag() : null}
            </div>
          </div>
          <div className="gov-number-region-container">
            <div className="gov-number-region-name">
              {country}
            </div>
            {country === 'RUS' ? renderFlag() : null}
          </div>
        </div>
      </div>
    </div>
  );
}
