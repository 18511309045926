import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { responseLogWithNotification } from '../../../utils/utils';
import { putZone } from '../actions';
import notificationDecorator from '../../Notifications/notificationDecorator';

export default function ZonesEditPupUp(props) {
  const { item, onHide, loadData } = props;
  const dispatch = useDispatch();
  const putZoneById = (id, date) => {
    dispatch(putZone({ id, date })).then(() => {
      notificationDecorator({
        title: 'Обновление зоны',
        message: 'Обновлены зоны успешно',
      });
      loadData();
    }).catch(responseLogWithNotification);
  };

  const {
    control, getValues, watch,
  } = useForm({ defaultValues: { ...item, coordinates: [] } });

  return (
    <Dialog
      className={classNames('zone-card-edit-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Редактирование зоны {item.name}</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={() => { onHide(); }} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content custom-scroll-theme">
        <FieldDTO
          type="text"
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'name',
            type: String,
            validators: [],
            labelKey: 'zones.name',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'total_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.total',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'total_free_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.total_free_spaces',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'total_busy_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.total_busy_spaces',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'regular_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.regular_spaces',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          key={item.regular_free_spaces}
          disabled
          dtoObject={{
            name: 'regular_free_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.free',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'regular_busy_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.busy',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'benefit_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.lightweight',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'benefit_free_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.benefit_free_spaces',
          }}
          control={control}
        />
        <FieldDTO
          type="text"
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'benefit_busy_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.benefit_busy_spaces',
          }}
          control={control}
        />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={() => { putZoneById(item.id, getValues()); onHide(); }} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
