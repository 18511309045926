import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  LOAD_HOST_LIST,
  LOAD_ACTION_HISTORY,
  LOAD_HOST_ACTIVITIES,
  CREATE_HOST,
  LOAD_HOST_TYPE,
  LOAD_HOST_COMPONENTS_TYPE,
  UPDATE_HOST,
} from '../actions';
import { CREATE, DELETE_BY_ID, LOAD_BY_ID } from '../../../configs/actionNames';
import { HOSTS_MODULE } from '../../../configs/moduleNames';
import loadHostList from './loadHostList';
import loadActionHistory from './loadActionHistory';
import executeComand from './executeComand';
import loadHostActivities from './loadHostActivities';
import loadHostById from './loadHostById';
import createHost from './createHost';
import updateHost from './updateHost';
import deleteHostById from './deleteHostById';
import loadHostTypeList from './loadHostTypeList';
import loadHostComponentsType from './loadHostComponentsType';

export default function* sagas() {
  yield takeLatest(HOSTS_MODULE + LOAD_HOST_LIST, loadHostList);
  yield takeLatest(HOSTS_MODULE + LOAD_ACTION_HISTORY, loadActionHistory);
  yield takeEvery(HOSTS_MODULE + CREATE, executeComand);
  yield takeLatest(HOSTS_MODULE + LOAD_HOST_ACTIVITIES, loadHostActivities);
  yield takeEvery(HOSTS_MODULE + LOAD_BY_ID, loadHostById);
  yield takeEvery(HOSTS_MODULE + CREATE_HOST, createHost);
  yield takeEvery(HOSTS_MODULE + UPDATE_HOST, updateHost);
  yield takeEvery(HOSTS_MODULE + DELETE_BY_ID, deleteHostById);
  yield takeLatest(HOSTS_MODULE + LOAD_HOST_TYPE, loadHostTypeList);
  yield takeLatest(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE, loadHostComponentsType);
}
