import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { responseLogWithNotification, getPaginationOpts, useUpdatePeriod } from '../../../utils/utils';
import { getPaymentData } from '../../PaymentLog/reducer';
import { getSessionData } from '../../SessionLog/reducer';
import '../../EventLog/components/event-log-page.scss';
import { sessionLogColumns } from '../../SessionLog/constants';
import { paymentLogColumns } from '../../PaymentLog/constants';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import { loadPaymentList } from '../../PaymentLog/actions';
import { loadSessionList } from '../../SessionLog/actions';
import PaymentLogGrid from '../../PaymentLog/components/PaymentLogGrid';
import defaultCarImg from '../../../theme/img/default-car.png';
import { Icons } from '../../../components/base/BootstrapComponents';
import SessionLogGrid from '../../SessionLog/components/SessionLogGrid';
import ClientLpnEditPopUp from './ClientLpnEditPopUp';
import { getSystemData } from '../../System/reducer';
import Loader from '../../../components/base/Loader/Loader';

export default function ClientStatus(props) {
  const dispatch = useDispatch();
  const basicKeys = ['status', 'id', 'parking_zone', 'client', 'lpn', 'description', 'type', 'expiry_at', 'issue_at'];
  const [tab, setTab] = useState(1);
  const [isPopUp, setIsPopUp] = useState(false);
  const { selectedItem, columns, loadList } = props;
  const navigate = useNavigate();
  const { updatePeriod } = useSelector(getSystemData);
  const { paymentList, paymentListMeta } = useSelector(getPaymentData);
  const { sessionList, sessionListMeta } = useSelector(getSessionData);

  const handleLoadingPaymentList = opts => {
    const paginationOpts = getPaginationOpts(paymentListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { client_id: selectedItem.id },
    };
    return dispatch(loadPaymentList(requestData)).catch(responseLogWithNotification);
  };

  const handleLoadingSessionList = opts => {
    const paginationOpts = getPaginationOpts(sessionListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { client_id: selectedItem.id },
    };
    return dispatch(loadSessionList(requestData)).catch(responseLogWithNotification);
  };

  const loadData = () => {
    const p1 = handleLoadingPaymentList();
    const p2 = handleLoadingSessionList();
    return Promise.all([p1, p2]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);
  return (
    <div className="client-status">
      <div className="client-basic-information">
        <span className="client-basic-information-title">Основная информация</span>
        <div className="client-basic-img" alt="avatar" style={{ background: `URL(${selectedItem.img || defaultCarImg})`, backgroundSize: 'cover' }} />
        <div className="client-basic-information-box">
          {getColumnsForTable(columns, basicKeys).map((column, index) => (
            <div className="client-basic-information-box-item" key={index}>
              <div className="client-basic-information-box-item-title">{column.text}</div>
              <div className="client-basic-information-box-item-content">
                {column.formatter ? column.formatter(selectedItem[column.dataField]) : selectedItem[column.dataField]}
                {column.dataField === 'lpn' ? (
                  <>
                    {selectedItem.lpn}
                    <div className="client-basic-control-button" onClick={() => setIsPopUp(true)}>
                      <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="client-status-table-box">
        <div className="client-status-tab-menu">
          <div className={classNames('client-status-tab-item', { selected: tab === 1 })} onClick={() => setTab(1)}>Журнал сессий</div>
          <div className={classNames('client-status-tab-item', { selected: tab === 2 })} onClick={() => setTab(2)}>Журнал платежей</div>
        </div>
        <div className="client-status-tables-container">
          <div className="client-status-tables-container-title">
            <div className="client-status-title">{tab === 1 ? 'Журнал сессий' : 'Журнал платежей'}</div>
            <div className="client-status-show-table" onClick={() => navigate(tab === 1 ? `/session-log?client_id=${encodeURIComponent(selectedItem.id)}` : `/payment-log?client_id=${encodeURIComponent(selectedItem.id)}`)}>{tab === 1 ? 'Все сессии клиента' : 'Все платежи клиента'}</div>
          </div>
          { tab === 1 ? <SessionLogGrid meta={sessionListMeta} data={sessionList} columns={getColumnsForTable(sessionLogColumns, ['num', 'ended_at', 'started_at', 'status', 'updated_at', 'lpn'])} /> : null }
          { tab === 2 ? <PaymentLogGrid meta={paymentListMeta} data={paymentList} columns={getColumnsForTable(paymentLogColumns, ['host', 'paid_at', 'amount', 'rrn', 'masked_pan', 'method', 'type', 'setting'])} /> : null }
          { isPopUp ? <ClientLpnEditPopUp loadList={loadList} onHide={() => setIsPopUp(false)} item={selectedItem} /> : null }
        </div>
      </div>
    </div>
  );
}
