import {
  MoreFormatter,
  statusFormatter,
  dateOrDateTimeFormatter,
  eventFormatter,
} from '../../utils/formatters';

export const eventLogColumns = [
  {
    dataField: 'host_name',
    id: 'host',
    text: 'Имя хоста (№)',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    // formatter: e => hostNameFormatter(e.name),
    dataClassName: 'align-middle',
    disablePosition: false,
  },
  {
    dataField: 'created_at',
    text: 'Дата и время',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'error_level',
    text: 'Уровень',
    disableSort: true,
    headerClassName: 'column-3',
    dataClassName: 'align-middle text-center',
    formatter: statusFormatter,
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'description',
    text: 'Событие',
    disableSort: true,
    formatter: eventFormatter,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'client_id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'session_ticket_number',
    text: 'Номер талона',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'session_id',
    text: 'Номер сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    // formatter: e => e.id,
    hidden: true,
    disablePosition: false,
  },
];
