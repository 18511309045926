import { handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { HOSTS_MODULE } from '../../configs/moduleNames';
import {
  LOAD_HOST_LIST,
  LOAD_ACTION_HISTORY, LOAD_HOST_ACTIVITIES,
  LOAD_HOST_TYPE,
  LOAD_HOST_COMPONENTS_TYPE,
} from './actions';

const initialState = {
  hostList: [],
  hostListMeta: [],
  hostActionHistory: [],
  hostActionHistoryMeta: [],
  loadingActionHistory: false,
  loadingHostList: false,
  hostActivitiesList: [],
  hostActivitiesListMeta: {},
  loadingHostActivitiesList: false,
  hostTypeList: [],
  loadinghostTypeList: false,
  hostComponentsTypeList: [],
  loadingHostComponentsTypeList: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(HOSTS_MODULE + LOAD_HOST_COMPONENTS_TYPE, 'hostComponentsTypeList', 'loadingHostComponentsTypeList'),
  ...createLoadListReducerWithBegin(HOSTS_MODULE + LOAD_HOST_TYPE, 'hostTypeList', 'loadinghostTypeList'),
  ...createLoadListReducerWithBegin(HOSTS_MODULE + LOAD_HOST_LIST, 'hostList', 'loadingHostList'),
  ...createLoadListReducerWithBegin(HOSTS_MODULE + LOAD_ACTION_HISTORY, 'hostActionHistory', 'loadingActionHistory'),
  ...createLoadListReducerWithBegin(HOSTS_MODULE + LOAD_HOST_ACTIVITIES, 'hostActivitiesList', 'loadingHostActivitiesList'),
}, initialState);

export default reducer;

export const getHostData = createSelector(
  [state => state[HOSTS_MODULE]],
  state => {
    const {
      hostList,
      hostListMeta,
      hostActionHistory,
      hostActionHistoryMeta,
      loadingActionHistory,
      loadingHostList,
      hostActivitiesList,
      hostActivitiesListMeta,
      loadingHostActivitiesList,
      hostComponentsTypeList,
      hostTypeList,
    } = state;

    return {
      hostListMeta,
      hostList,
      hostActionHistory,
      hostActionHistoryMeta,
      loadingActionHistory,
      loadingHostList,
      hostActivitiesList,
      hostActivitiesListMeta,
      loadingHostActivitiesList,
      hostComponentsTypeList,
      hostTypeList,
    };
  },
);
