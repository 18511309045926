import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import { updateUser } from '../actions';
import notificationDecorator from '../../Notifications/notificationDecorator';
import { responseLogWithNotification } from '../../../utils/utils';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';

export default function ClientLpnEditPopUp(props) {
  const { onHide, item, loadList = () => {} } = props;
  const dispatch = useDispatch();
  const { control, getValues } = useForm({
    defaultValues: { lpn: item.lpn },
  });

  const updateClient = () => {
    const data = getValues();
    dispatch(updateUser({ id: item.id, userData: { ...item, ...data } }))
      .then(() => {
        notificationDecorator({
          title: 'Редактирование клиента',
          message: 'Редактирование клиента успешно',
        });
        onHide();
        loadList();
      })
      .catch(responseLogWithNotification);
  };

  return (
    <Dialog
      className={classNames('slp-edit-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Редактирование распознанного номера ГРЗ</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <FieldDTO
          control={control}
          type="text"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'lpn',
            type: String,
            validators: [],
            labelKey: 'slp.governmentNumber',
          }}
        />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={updateClient} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
