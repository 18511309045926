import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/base/Pagination/Pagination';
import {
  EquipmentFormatter,
  visualFormatter,
  MoreFormatter,
  logFormatter,
  HostNameAndStatusFormatter,
  vehicleRegistrationPlateFormatter,
} from '../../../utils/formatters';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';
import { Icons } from '../../../components/base/BootstrapComponents';
import { responseLogWithNotification } from '../../../utils/utils';
import notificationDecorator from '../../Notifications/notificationDecorator';
import { createComand } from '../actions';

export default function HostGrid(props) {
  const dispatch = useDispatch();
  const executeComand = (id, command) => {
    dispatch(createComand({ id, command })).then(() => {
      notificationDecorator({
        title: 'Создание команды',
        message: 'Создание команды успешно',
      });
    }).catch(responseLogWithNotification);
  };

  const defaultColumns = [
    {
      dataField: 'host',
      text: 'Объект',
      formatter: (_, a) => (<HostNameAndStatusFormatter item={a} isComponentBase />),
      disableSort: true,
      hidden: true,
      headerClassName: 'column-0',
      dataClassName: 'align-middle',
    },
    {
      dataField: 'active_components',
      text: 'Периферийные модули',
      formatter: EquipmentFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-1',
      hidden: true,
    },
    {
      dataField: 'vehicle_detection_state',
      text: 'Визуализация',
      formatter: visualFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-2',
      hidden: true,
    },
    {
      dataField: 'last_event',
      text: 'ГРЗ',
      formatter: vehicleRegistrationPlateFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-3',
      hidden: true,
    },
    {
      dataField: 'last_event',
      text: 'Последние данные',
      formatter: date => logFormatter(date && date.description ? date.description : '', date),
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-4',
      hidden: true,
    },
    {
      dataField: 'available_commands',
      text: 'Доступные действия',
      formatter: (_, item) => (
        <div className="control-box-formatter">
          {item.available_commands.map((command, key) => (
            <div className="control-box-button" key={key}>
              <Icons width={28} height={28} customIcon icon={command} className="button-icons" />
              <div className="expanded-click-zone" onClick={e => { executeComand(item.id, command); e.stopPropagation(); e.preventDefault(); }} />
            </div>
          ))}
        </div>
      ),
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'align-middle column-5',
      hidden: true,
    },
  ];

  const {
    data,
    loadData,
    onClickByRow,
    meta,
    columns = defaultColumns,
  } = props;

  return (
    <div className="host-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="host-grid-main" data={data} columns={columns} name="session-list" onClickByRow={onClickByRow} settingsFormatter={MoreFormatter} />
        </div>
      </div>
      <Pagination meta={meta} list={data} handleChangeMeta={loadData} />
    </div>
  );
}
