import React, { useState } from 'react';
import Pagination from '../../../components/base/Pagination/Pagination';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';
import { MoreFormatter } from '../../../utils/formatters';
import { sessionLogColumns } from '../constants';

export default function SessionLogGrid(props) {
  const {
    data = [],
    loadData = () => {},
    onClickByRow = () => {},
    meta = {},
  } = props;

  return (
    <div className="slp-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="slp-grid" onClickByRow={onClickByRow} data={data} columns={sessionLogColumns} name="slp-list" settingsFormatter={MoreFormatter} />
        </div>
      </div>
      <Pagination meta={meta} list={data} handleChangeMeta={loadData} />
    </div>
  );
}
