import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import './event-log-page.scss';
import { useSearchParams } from 'react-router-dom';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getEventData } from '../reducer';
import { statusNotificationFormatter } from '../../../utils/formatters';
import { loadEventList, loadEventStatus } from '../actions';
import TextInput from '../../../components/fields/TextInput/TextInput';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import EventLogGrid from './EventLogGrid';
import EventLogPopUp from './EventLogPopUp';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import {
  responseLogWithNotification,
  getPaginationOpts,
  useUpdatePeriod,
  getJsonFromStorage,
} from '../../../utils/utils';
import { getSystemData } from '../../System/reducer';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import EventLogFilterForm from './EventLogFilterForm';
import Loader from '../../../components/base/Loader/Loader';

export default function EventLogPage() {
  const dispatch = useDispatch();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [searchParams] = useSearchParams();

  const defaultFilterValues = {
    created_at: '',
    client_id: '',
    description: '',
    session_id: '',
    session_ticket_number: '',
    session: '',
    host_name: '',
    error_level: '',
    event: '',
  };

  const { updatePeriod } = useSelector(getSystemData);
  const {
    eventList, eventListMeta, eventStatusList, loadingEventList, loadEventStatusList,
  } = useSelector(getEventData);
  const eventStatusOptions = eventStatusList.map(event => ({ nameId: event.label, value: event.value }));

  const handlerClickRow = item => {
    setSelectedRow(item);
    setIsPopup(true);
  };

  // const [columns, setColumns] = useState(loadDataFromStorage());
  const initial = getJsonFromStorage('elp-filter', defaultFilterValues);
  const host_name = searchParams.get('host_name');
  const session_id = searchParams.get('session_id');
  const {
    control, register, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: { ...initial, host_name: host_name || initial.host_name, session_id: session_id || initial.session_id } });

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(eventListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const p1 = dispatch(loadEventList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => {
      loadData();
      localStorage.setItem('elp-filter', JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(loadEventStatus()).catch(responseLogWithNotification);
  }, []);

  return (
    <div className="event-log-page">
      <div className="elp-container">
        <div className="elp-inputs-box">
          <PageHeader header="Журнал событий">
            <div className="header-loader"><Loader loading={loadingEventList || loadEventStatusList} /></div>
          </PageHeader>
          <div className="elp-inputs">
            <FieldDTO
              control={control}
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              addPlaceholder
              dtoObject={{
                name: 'search',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              className="small no-border search-field "
            />
            <FieldDTO
              control={control}
              type="text"
              dtoObject={{
                name: 'host_name',
                type: String,
                validators: [],
                labelKey: 'elp.hostName',
              }}
              addPlaceholder
              component={TextInput}
              className="no-border"
            />
            <FieldDTO
              control={control}
              type="text"
              dtoObject={{
                name: 'error_level',
                type: String,
                validators: [],
                labelKey: 'elp.status',
              }}
              // renderOption={item => (
              //   statusNotificationFormatter(item.value)
              // )}
              addPlaceholder
              component={CustomSelectInput}
              options={eventStatusOptions}
              className="no-border select-field"
            />
            <SeparateBox />
            <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
            {isOpenFilter
              ? (
                <EventLogFilterForm
                  onHide={() => { setIsOpenFilter(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  control={control}
                  reset={reset}
                  defaultValues={defaultFilterValues}
                  eventListMeta={eventListMeta}
                  eventStatusOptions={eventStatusOptions}
                />
              )
              : null}
          </div>
        </div>
        <EventLogGrid data={eventList} meta={eventListMeta} loadData={loadData} onClickByRow={handlerClickRow} />
        { isPopup ? <EventLogPopUp onHide={() => setIsPopup(false)} selectedItem={selectedRow} /> : null }
      </div>
    </div>
  );
}
