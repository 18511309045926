import React from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';

export default function ClientDeletePopUp(props) {
  const {
    onHide,
    client_ids,
    removeClient,
    setSelected,
  } = props;
  return (
    <Dialog
      className={classNames('client-popup-delete')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <div className="hff-title-text f2">{client_ids && client_ids.length > 1 ? 'Удалить клиентов?' : 'Удалить клиента?' }</div>
        )}
      />
      <DialogContent className="hff-content">
        {client_ids ? (
          client_ids.map((id, index) => (
            <div key={index} className="delete-item">
              <div className="delete-item-text">{id}</div>
              <Icons
                customIcon
                className="hff-close"
                onClick={() => {
                  if (client_ids.length === 1) {
                    onHide();
                  }
                  setSelected(client_ids.filter(userId => userId !== id));
                }}
                width={14}
                height={14}
                icon="clear"
              />
            </div>
          ))
        ) : null}
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Удалить" onClick={e => { removeClient(client_ids)(e); onHide(); }} />
        <CustomButton isCancel text="Отменить" onClick={() => { onHide(); setSelected([]); }} />
      </DialogFooter>
    </Dialog>
  );
}
