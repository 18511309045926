import {
  clientStatusFormatter,
  clientTypeFormatter,
  controlBoxFormatter,
  dateOrDateTimeFormatter, vehicleRegistrationPlateFormatter,
} from '../../utils/formatters';

export const defaultColumns = [
  {
    dataField: 'checkBox',
    disableSort: true,
    text: 'Выбор строки',
    hidden: true,
    headerClassName: 'checkbox-column',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'id-column',
    hidden: true,
  },
  {
    dataField: 'type_label',
    text: 'Тип клиента',
    disableSort: true,
    hidden: true,
    headerClassName: 'type-column',
    formatter: clientTypeFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'lpn',
    text: 'ГРЗ',
    disableSort: true,
    hidden: true,
    formatter: (_, item) => vehicleRegistrationPlateFormatter(item),
    headerClassName: 'lpn-column',
    dataClassName: 'align-middle',
  },
  {
    dataField: 'parking_zone',
    text: 'Зона клиента',
    disableSort: true,
    formatter: zone => (zone && zone.name ? zone.name : null),
    dataClassName: 'align-middle',
    headerClassName: 'parking-zone-column',
    // formatter: e => e.name,
    hidden: true,
  },
  {
    dataField: 'issue_at',
    text: 'Срок действия с',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'period-column',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'expiry_at',
    text: 'Срок действия по',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'period-column',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус',
    disableSort: true,
    headerClassName: 'status-column',
    dataClassName: 'align-middle',
    formatter: clientStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'control',
    text: 'Доступные действия',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'control-column',
    formatter: controlBoxFormatter,
    hidden: true,
  },
  {
    dataField: 'masked_pan',
    text: 'Номер карты',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'dateOfUse',
    text: 'Срок действия',
    disableSort: true,
    headerClassName: 'column-11',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'description',
    text: 'Комментарий',
    disableSort: true,
    headerClassName: 'column-10',
    dataClassName: 'align-middle',
    hidden: false,
  },
  // {
  //   dataField: 'setting',
  //   text: <Icons width={24} height={24} customIcon icon=gear className=button-icons more-vert />,
  //   formatter: MoreFormatter,
  //   disableSort: true,
  //   dataClassName: 'row-full-click',
  //   headerClassName: 'text-center column-9',
  //   // onClick: () => setSetting(true),
  //   hidden: true,
  //   disable: true,
  // },
];

export const statusOptions = [
  { value: 'blocked', name: 'Заблокирован' },
  { value: 'active_outside', name: 'Активен за территорией' },
  { value: 'active_inside', name: 'Активен' }];

export const defaultFilterValues = {
  checkBox: '',
  search: '',
  id: '',
  type: '',
  lpn: '',
  parking_zone_id: '',
  issue_at: '',
  expiry_at: '',
  status: '',
  control: '',
  masked_pan: '',
  dateOfUse: '',
  feedback: '',
};

export const lpnTypeOptions = [
  { value: 'ru', name: 'РФ' },
  { value: 'ru_red', name: 'РФ (дипломатический)' },
  { value: 'ru_black', name: 'РФ (военный)' },
  { value: 'am', name: 'Армения' },
  { value: 'az', name: 'Азербайджан' },
  { value: 'by', name: 'Беларусь' },
  { value: 'kg', name: 'Киргизия' },
  { value: 'kz', name: 'Казахстан' },
  { value: 'md', name: 'Молдова' },
  { value: 'ru_blue', name: 'РФ (полиция)' },
  { value: 'ru_yellow', name: 'РФ (автобус/такси)' },
  { value: 'ua', name: 'Украина' },
  { value: 'uz', name: 'Узбекистан' },
];

export const vehicleTypeOptions = [
  { value: 'car', name: 'Автомобиль' },
  { value: 'bus', name: 'Автобус' },
  { value: 'truck', name: 'Грузовой автомобиль' },
  { value: 'motorcycle', name: 'Мотоцикл' },
];
