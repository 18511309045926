import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import renderMessage from '../renderMessage';
import { required } from '../../../utils/validation';
import './TextInputStyles.scss';

class TextInput extends Component {
  renderTextareaControl = props => {
    const addProps = {};
    return (
      <textarea
        {...props}
        {...addProps}
      >
        {props.value}
      </textarea>
    );
  };

  renderInputControl = props => {
    const {
      inputBefore = '',
      inputAfter = '',
      fieldState = {
        error: '',
        touched: false,
      },
      content,
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;

    const isError = Boolean(error);
    if (inputBefore || inputAfter) {
      return (
        <div className={classNames('input-group', { 'is-invalid': isError && touched }, { 'with-before': !!inputBefore }, { 'with-after': !!inputAfter })}>
          {inputBefore ? <span className="input-group-text">{inputBefore}</span> : null}
          <input {...props} />
          {inputAfter ? <span className="input-group-text">{inputAfter}</span> : null}
        </div>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        <input
          {...props}
        />
        {content}
      </div>
    );
  };

  renderMaterialInputControl = props => {
    const {
      inputBefore = '',
      inputAfter = '',
      withLabel = false,
      label = '',
      dtoObject,
      customValidator = [],
      fieldState = {
        error: '',
        touched: false,
      },
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;

    const isError = Boolean(error);
    const isRequired = (dtoObject
        && dtoObject.validators
        && dtoObject.validators.length
        && !!dtoObject.validators.find(validator => validator.toString() === required.toString()))
      || !!customValidator.find(validator => validator.toString() === required.toString());
    if (props.placeholder) {
      delete props.placeholder;
    }
    return (
      <div className={classNames('input-block', { 'is-invalid': isError && touched })}>
        {inputBefore ? <span className="input-before-block">{inputBefore}</span> : null}
        <input {...props} />
        {withLabel ? (
          <div htmlFor={props.id} className="form-input-label">
            {renderMessage(label)}
            {isRequired ? <span className="form-label-mark-required">*</span> : null}
          </div>
        ) : null}
        {inputAfter ? <span className="input-after-block">{inputAfter}</span> : null}
      </div>
    );
  };

  render() {
    const {
      type = 'text',
      input = '',
      disabledResize,
      placeholder = '',
      className = '',
      inputClassName = '',
      content = null,
      autocomplete = 'off',
      textMuted = '',
      disabled = false,
      largeControl = false,
      textarea = false,
      readOnly = false,
      withLabel = false,
      inputProps = {},
      variant = 'default',
      label,
      dtoObject,
      customValidator = [],
      controlId = `text-input-${Math.random() * 10000}`,
      refFunction = () => {},
      fieldState = {
        error: '',
        touched: false,
      },
    } = this.props;

    const {
      error,
      isTouched: touched,
    } = fieldState;

    const isError = Boolean(error);

    const controlProps = {
      type,
      className: classNames('form-control', inputClassName, { 'form-control-lg': largeControl }, { 'is-invalid': isError && touched }, { 'text-area': textarea }, { 'resize-none': disabledResize }),
      id: controlId,
      'aria-describedby': `${controlId}-help`,
      disabled,
      placeholder,
      autoComplete: autocomplete,
      readOnly,
      ...input,
      ...inputProps,
      value: (inputProps.value !== undefined && inputProps.value !== null) ? inputProps.value : '',
      content,
    };

    const isRequired = (dtoObject
        && dtoObject.validators
        && dtoObject.validators.length
        && !!dtoObject.validators.find(validator => validator.toString() === required.toString()))
      || !!customValidator.find(validator => validator.toString() === required.toString());

    if (withLabel && variant === 'material') {
      delete controlProps.placeholder;
    }

    return (
      <div className={classNames('form-control-wrapper', className, { material: variant === 'material' }, { default: variant !== 'material' })} ref={refFunction}>
        {
          withLabel && variant !== 'material'
            ? (
              <label htmlFor={controlId} className="form-label">
                {renderMessage(label)}{isRequired ? <span className="form-label-mark-required">*</span> : null}
              </label>
            )
            : null
        }
        {
          variant === 'material'
            ? !textarea ? this.renderMaterialInputControl(controlProps) : this.renderTextareaControl(controlProps)
            : !textarea ? this.renderInputControl(controlProps) : this.renderTextareaControl(controlProps)
        }
        {textMuted ? <div id={`${controlId}-help`} className="form-text">{renderMessage(textMuted)}</div> : null}
        {touched && isError ? <div className="invalid-feedback">{renderMessage(error)}</div> : null}
      </div>
    );
  }
}

export default compose(
  injectIntl,
)(TextInput);
