import React from 'react';
import Pagination from '../../../components/base/Pagination/Pagination';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';
import { controlBoxFormatter, dateOrDateTimeFormatter, MoreFormatter } from '../../../utils/formatters';

export default function ServiceMonitoringGrid(props) {
  const {
    data,
    meta,
    loadData,
  } = props;

  const columns = [
    {
      dataField: 'name',
      text: 'Сервис',
      disableSort: true,
      hidden: true,
      headerClassName: 'column-1',
      dataClassName: 'align-middle',
    },
    {
      dataField: 'code',
      text: 'Код',
      disableSort: true,
      hidden: true,
      headerClassName: 'column-1',
      dataClassName: 'align-middle',
    },
    {
      dataField: 'last_ping_at',
      text: 'Последние данные',
      disableSort: true,
      dataClassName: 'align-middle',
      formatter: date => dateOrDateTimeFormatter(date),
      headerClassName: 'column-2',
      hidden: true,
    },
    // {
    //   dataField: 'available_commands',
    //   text: 'Доступные действия',
    //   formatter: controlBoxFormatter,
    //   disableSort: true,
    //   dataClassName: 'align-middle text-center',
    //   headerClassName: 'align-middle column-3',
    //   hidden: true,
    // },
  ];

  return (
    <div className="sm-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="sm-grid" data={data} columns={columns} name="sm-list" settingsFormatter={MoreFormatter} />
        </div>
      </div>
      <Pagination meta={meta} list={data} handleChangeMeta={loadData} />
    </div>
  );
}
