import { takeLatest } from 'redux-saga/effects';
import { LOAD_EVENT_LIST, LOAD_EVENT_STATUS_LIST } from '../actions';

import loadEventList from './loadEventList';
import loadEventStatusList from './loadEventStatusList';
import { EVENT_LOG_PAGE_MODULE } from '../../../configs/moduleNames';

export default function* sagas() {
  yield takeLatest(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST, loadEventList);
  yield takeLatest(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST, loadEventStatusList);
}
