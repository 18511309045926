import { takeEvery, takeLatest } from 'redux-saga/effects';
import { CLIENTS_MODULE, ZONES_PAGE_MODULE } from '../../../configs/moduleNames';
import { MODULE_NAME } from '../constants';
import { LOAD_SESSION_LIST, LOAD_SESSION_ID, LOAD_SESSION_STATUS_LIST } from '../actions';
import { LOAD_ZONE_LIST } from '../../Zones/actions';
import { LOAD_CLIENT_TYPE_LIST } from '../../Clients/actions';

import loadSessionList from './loadSessionList';
import loadSessionId from './loadSessionId';
import loadSessionStatusList from './loadSessionStatusList';
import loadZoneList from '../../Zones/sagas/loadZoneList';
import loadClientTypeList from '../../Clients/sagas/loadClientTypeList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_SESSION_LIST, loadSessionList);
  yield takeLatest(MODULE_NAME + LOAD_SESSION_ID, loadSessionId);
  yield takeEvery(MODULE_NAME + LOAD_SESSION_STATUS_LIST, loadSessionStatusList);
}
