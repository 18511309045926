import { handleActions } from 'redux-actions';

import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import {
  CLIENTS_MODULE, SESSION_LOG_PAGE_MODULE, SYSTEM_MODULE, ZONES_PAGE_MODULE,
} from '../../configs/moduleNames';
import {
  LOAD_SESSION_LIST, LOAD_SESSION_STATUS_LIST, LOAD_SESSION_ID,
} from './actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';
import {
  LOAD_CLIENT_TYPE_LIST,
} from '../Clients/actions';
import {
  LOAD_EVENT_LIST,
} from '../EventLog/actions';
import {
  LOAD_PAYMENT_LIST,
} from '../PaymentLog/actions';

const initialState = {
  sessionList: [],
  sessionStatusList: [],
  sessionListMeta: [],
  paymentList: [],
  eventList: [],
  session: {},
  loadingSessionList: false,
  loadingSessionStatus: false,
  loadingSessionId: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_LIST, 'sessionList', 'loadingSessionList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_STATUS_LIST, 'sessionStatusList', 'loadingSessionStatus'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_ID, 'session', 'loadingSessionId'),
}, initialState);

export default reducer;

export const getSessionData = createSelector(
  [state => state[SESSION_LOG_PAGE_MODULE]],
  state => {
    const {
      sessionList,
      sessionStatusList,
      sessionListMeta,
      session,
    } = state;
    return {
      sessionList,
      session,
      sessionStatusList,
      sessionListMeta,
    };
  },
);
