import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { responseLogWithNotification, getPaginationOpts, useUpdatePeriod } from '../../../utils/utils';
import { getEventData } from '../../EventLog/reducer';
import { getPaymentData } from '../../PaymentLog/reducer';
import '../../EventLog/components/event-log-page.scss';
import { eventLogColumns } from '../../EventLog/constants';
import { sessionLogColumns } from '../constants';
import { paymentLogColumns } from '../../PaymentLog/constants';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import EventLogGrid from '../../EventLog/components/EventLogGrid';
import SessionLogEditPopUp from './SessionLogEditPopUp';
import { loadPaymentList } from '../../PaymentLog/actions';
import { loadEventList } from '../../EventLog/actions';
import PaymentLogGrid from '../../PaymentLog/components/PaymentLogGrid';
import defaultCarImg from '../../../theme/img/default-car.png';
import bykeImg from '../../../theme/img/byke.png';
import truckImg from '../../../theme/img/truck.png';
import busImg from '../../../theme/img/bus.png';
import { Icons } from '../../../components/base/BootstrapComponents';
import { loadSessionList } from '../actions';
import { getSystemData } from '../../System/reducer';

export default function SessionLogStatus(props) {
  const dispatch = useDispatch();
  const basicKeys = ['status', 'client_id', 'parking_zone', 'client', 'lpn'];
  const [tab, setTab] = useState(1);
  const [isPopUp, setIsPopUp] = useState(false);
  const listImgType = [
    { value: 'bus', img: busImg },
    { value: 'car', img: defaultCarImg },
    { value: 'truck', img: truckImg },
    { value: 'motorcycle', img: bykeImg },
  ];
  const {
    selectedItem,
    columns,
  } = props;

  const navigate = useNavigate();
  const carTypeImg = listImgType.find(e => e.value === selectedItem.vehicle_type);
  const { paymentList, paymentListMeta } = useSelector(getPaymentData);
  const { eventList, eventListMeta } = useSelector(getEventData);
  const { updatePeriod } = useSelector(getSystemData);

  const handleLoadingPaymentList = opts => {
    const paginationOpts = getPaginationOpts(paymentListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { session_id: selectedItem.id },
    };
    return dispatch(loadPaymentList(requestData)).catch(responseLogWithNotification);
  };

  const handleLoadingEventList = opts => {
    const paginationOpts = getPaginationOpts(eventListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { session_id: selectedItem.id },
    };
    return dispatch(loadEventList(requestData)).catch(responseLogWithNotification);
  };

  const loadData = () => {
    const p1 = handleLoadingEventList();
    const p2 = handleLoadingPaymentList();
    return Promise.all([p1, p2]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  return (
    <div className="slp-status">
      <div className="slp-basic-information">
        <div className="slp-basic-img" alt="avatar" style={{ background: `URL(${(carTypeImg && carTypeImg.img) || defaultCarImg})`, backgroundSize: 'cover' }} />
        <span className="slp-basic-information-title">Основная информация</span>
        <div className="slp-basic-information-box">
          {getColumnsForTable(sessionLogColumns, basicKeys).map((column, index) => (
            <div className="slp-basic-information-box-item" key={index}>
              <div className="slp-basic-information-box-item-title">{column.text}</div>
              <div className="slp-basic-information-box-item-content">
                {column.formatter ? column.formatter(selectedItem[column.dataField]) : selectedItem[column.dataField]}
                {column.dataField === 'lpn' ? (
                  <div className="slp-basic-control-button" onClick={() => setIsPopUp(true)}>
                    <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="slp-status-table-box">
        <div className="slp-status-tab-menu">
          <div className={classNames('slp-status-tab-item', { selected: tab === 1 })} onClick={() => setTab(1)}>Журнал событий</div>
          <div className={classNames('slp-status-tab-item', { selected: tab === 2 })} onClick={() => setTab(2)}>Журнал платежей</div>
        </div>
        <div className="slp-status-tables-container">
          <div className="slp-status-tables-container-title">
            <div className="slp-status-title">{tab === 1 ? 'Журнал событий' : 'Журнал платежей'}</div>
            <div className="slp-status-show-table" onClick={() => navigate(`${tab === 1 ? '/event-log' : '/payment-log'}?session_id=${encodeURIComponent(selectedItem.id)}`)}>Показать всё</div>
          </div>
          { tab === 1 ? <EventLogGrid handleLoadingEventList={handleLoadingEventList} meta={eventListMeta} data={eventList} columns={getColumnsForTable(eventLogColumns, ['created_at', 'client_id', 'session_ticket_number', 'session_id', 'error_level', 'description'])} /> : null }
          { tab === 2 ? <PaymentLogGrid handleLoadingPaymentList={handleLoadingPaymentList} meta={paymentListMeta} data={paymentList} columns={getColumnsForTable(paymentLogColumns, ['host', 'paid_at', 'amount', 'rrn', 'masked_pan', 'method_label', 'type_label', 'masked_pan'])} /> : null }
          { isPopUp ? <SessionLogEditPopUp onHide={() => setIsPopUp(false)} item={selectedItem} /> : null }
        </div>
      </div>
    </div>
  );
}
