import { useState } from 'react';
import moment from 'moment';
import { DateObject } from 'react-multi-date-picker';
import { intlShape } from '../components/base/IntlProviderWrapper';
import notificationDecorator from '../modules/Notifications/notificationDecorator';
import { DEFAULT_ITEMS_OF_PAGE } from '../configs';

const vehicleRegistrationPlateRegex = [
  {
    type: 'default',
    regex: [/\w{1}\s*\d{3}\s*\w{2}/, /\w{2}\s*\d{4}/, /\d{4}\s*\w{2}/],
  },
  {
    type: 'taxi',
    regex: [/\w{2}\s*\d{3}/],
  },
  {
    type: 'military',
    regex: [/\d{4}\s*\w{2}/, /\w{2}\s*\d{4}/, /\d{4}\s*\w{2}/],
  },
  {
    type: 'diplomat',
    regex: [/\d{3}\s*\w{2}\s*\d{1}/, /\d{3}\s*\w{1}\s*\d{3}/, /\w{1}\s*\d{5}/],
  },
  {
    type: 'police',
    regex: [/\d{1}\s*\w{4}/, /\d{3}\s*\w{1}/, /\d{4}\s*\w{1}/],
  },
];

export function copyText(text, defaultColumns) {
  const exportData = defaultColumns
    .filter(column => column.dataField !== 'checkBox')
    .map(column => {
      const value = text[column.dataField];
      if (typeof value === 'object' && value !== null) {
        return `${column.text}: ${JSON.stringify(value)}`;
      }
      return `${column.text}: ${value}`;
    }).join('\n');

  navigator.clipboard.writeText(exportData)
    .then(() => {
      notificationDecorator({
        title: 'Копирование в буфер',
        message: 'Успешно',
      });
    })
    .catch(err => {
      notificationDecorator({
        title: 'Копирование в буфер',
        message: 'Ошибка',
      });
    });
}

export function getJsonFromStorage(name, defaultValue = null) {
  const value = localStorage.getItem(name);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return defaultValue;
    }
  }
  return defaultValue;
}

export const useStoreState = function (initialState, name) {
  const [value, setter] = useState(getJsonFromStorage(name) || initialState);
  const setValue = value => {
    setter(value);
  };
  const save = value => {
    localStorage.setItem(name, JSON.stringify(value));
  };
  const reset = () => setValue(getJsonFromStorage(name) || initialState);
  return [value, setValue, reset, save];
};

export const getResponseError = (response, defaultMessage = '') => {
  const {
    formatMessage,
  } = intlShape;
  if (response && response.message) {
    let { message = '' } = response;
    if (response.error && response.error.code && !message) {
      message = formatMessage({ id: `error.code.${response.error.code}`, defaultMessage: ' ' }).trim();
    }

    return message || defaultMessage;
  }
  return defaultMessage;
};

export const parserRegNumber = (regNumber = '') => vehicleRegistrationPlateRegex.find(reg => !!regNumber.match(reg.regex));

export const isObject = object => object != null && typeof object === 'object';

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2))
      || (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const getFilterValue = (filterData, name, defaultValue = '') => (filterData[name]
  && filterData[name].value
  ? filterData[name].value
  : defaultValue);

export const isClear = obj => Object.values(obj).reduce((acc, cur) => {
  const value = cur;
  if (isObject(value)) {
    return acc && isClear(value);
  } if (Array.isArray(value)) {
    return acc && !value.length;
  }
  return acc && !value;
}, true);

export const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (!successful) {
      window.console.error('Fallback: Oops, unable to copy');
    }
  } catch (err) {
    window.console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {}, err => {
    window.console.error('Async: Could not copy text: ', err);
  });
};

export const getPathNameWithOutParameters = (path = '') => {
  if (!path) {
    return path;
  }
  return path.split(':')[0];
};

export const addZero = (len = 1, part = '') => {
  const arrayLen = len - String(part).length >= 0 ? len - String(part).length : 0;
  return (
    Array(arrayLen)
      .fill('0')
      .join('') + String(part)
  );
};

export const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const responseLog = response => window.console.error(response);
export const responseLogWithNotification = (response, title = '', message = '') => {
  const {
    formatMessage,
  } = intlShape;
  window.console.error(response);
  notificationDecorator({
    title: title || formatMessage({ id: 'load.data' }),
    message: getResponseError(response, message) || formatMessage({ id: 'failed' }),
    isError: true,
  });
};
export const getHostMetrics = (host, needMetrics = []) => {
  const { metrics = [] } = host;
  return metrics.map(m => ({ metric_key: m.metric_key, value: m.value })).filter(m => !needMetrics || !needMetrics.length || needMetrics.includes(m.metric_key));
};
export const getHostMetricsByKeys = (host, needMetrics = []) => {
  const metrics = getHostMetrics(host, needMetrics);
  return metrics.reduce((acc, current) => {
    acc[current.metric_key] = current.value;
    return acc;
  }, {});
};
export const getPaginationOpts = (listMeta = {}, opts = {}) => {
  const page = opts && !Number.isNaN(Number(opts.page))
    ? Number(opts.page)
    : listMeta && listMeta.page && !!Number(listMeta.page)
      ? Number(listMeta.page)
      : 1;
  const limit = opts && opts.limit && !!Number(opts.limit)
    ? Number(opts.limit)
    : listMeta && listMeta.limit && !!Number(listMeta.limit)
      ? Number(listMeta.limit)
      : DEFAULT_ITEMS_OF_PAGE;
  return {
    page,
    limit,
  };
};

export const getJSONFromString = value => {
  if (!value) {
    return null;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};
export const saveFilter = (value, name) => {
  if (name) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }
};
export const useFilterData = (initial = {}, callback = () => {}, name = '', useInitial = '') => {
  let savedValue = {};
  if (name) {
    savedValue = getJSONFromString(window.localStorage.getItem(name)) || {};
    if (savedValue && savedValue.date) {
      savedValue.date = savedValue.date.map(i => new DateObject({ date: moment(i).toDate() }));
    }
  }
  const [filter, setFilter] = useState(useInitial ? { ...initial } : { ...savedValue, ...initial });
  const saveFilter = value => {
    setFilter(value);
    if (name) {
      window.localStorage.setItem(name, JSON.stringify(value));
    }
  };
  const handleChangeFilter = (name, type = null, removeFalse = false) => event => {
    const filterData = { ...filter };
    const { value } = event.target;
    if (type) {
      filterData[name] = type(value);
    } else {
      filterData[name] = value;
    }
    if (value === null || (typeof value === 'string' && !value) || (removeFalse && !value)) {
      delete filterData[name];
    }
    saveFilter({ ...filterData });
    setTimeout(() => {
      callback({ filterData });
    }, 100);
  };
  const setFilterValue = filterData => {
    saveFilter({ ...filterData });
    setTimeout(() => {
      callback({ filterData });
    }, 100);
  };
  const clearFilter = (inputs = []) => {
    let filterData = { ...filter };
    if (inputs && inputs.length) {
      for (let i = 0, len = inputs.length; i < len; i++) {
        const name = inputs[i];
        if (name && filterData && filterData[name]) {
          delete filterData[name];
        }
      }
    } else {
      filterData = {};
    }
    saveFilter(filterData);
    setTimeout(() => {
      callback({ filterData });
    }, 100);
  };
  const getFilterValue = name => (filter && typeof filter[name] !== 'undefined' ? filter[name] : '');
  return {
    handleChangeFilter, filter, getFilterValue, clearFilter, setFilterValue,
  };
};
export const useSortData = (initial = '', callback = () => {}, name = '') => {
  let savedValue = [];
  if (name) {
    savedValue = getJSONFromString(window.localStorage.getItem(name)) || [];
  }
  const [sort, setSort] = useState([...initial, ...savedValue]);
  const saveSort = value => {
    setSort(value);
    if (name) {
      window.localStorage.setItem(name, JSON.stringify(value));
    }
  };
  const changeSort = name => () => {
    const sortData = [...sort];
    const index = sortData.findIndex(i => i === name || i === `-${name}`);
    if (index >= 0) {
      if (sortData[index] === name) {
        sortData[index] = `-${name}`;
      } else {
        sortData.splice(index, 1);
      }
    } else {
      sortData.push(name);
    }

    saveSort([...sortData]);
    setTimeout(() => {
      callback({ sortData });
    }, 100);
  };

  return { changeSort, sort };
};

export const filterByName = value => item => String(item.name).toLowerCase().includes(String(value).toLowerCase());

window.updateTimers = [];

export const useUpdatePeriod = function useUpdatePeriod(callback = Promise, updatePeriod, noPromise = false) {
  let timer = null;
  let period = updatePeriod || 0;
  let stopped = false;
  const setPeriod = value => {
    period = value || 0;
    load();
  };
  const destroy = () => {
    stopped = true;
    clear();
  };

  const clear = () => {
    if (timer) {
      const index = window.updateTimers.findIndex(i => timer === i);
      if (index > -1) {
        window.updateTimers.splice(index, 1);
      }
      clearTimeout(timer);
    }
  };
  const load = () => {
    clear();
    const c = callback();
    if (!noPromise) {
      // пока идет запрос нет таймера это пораждает баг с переключением на другой экран.
      c.finally(values => {
        if (period && !stopped) {
          timer = setTimeout(() => {
            load();
          }, period * 1000);
          window.updateTimers.push(timer);
        }
      });
    } else if (period) {
      timer = setTimeout(() => {
        load();
      }, period * 1000);
      window.updateTimers.push(timer);
    }
  };
  return [setPeriod, destroy, clear, load];
};

export const regNumEnToRu = text => {
  if (!text) {
    return text;
  }
  text = text
    .replace(/A/g, 'А')
    .replace(/B/g, 'В')
    .replace(/E/g, 'Е')
    .replace(/K/g, 'К')
    .replace(/M/g, 'М')
    .replace(/H/g, 'Н')
    .replace(/O/g, 'О')
    .replace(/P/g, 'Р')
    .replace(/C/g, 'С')
    .replace(/T/g, 'Т')
    .replace(/Y/g, 'У')
    .replace(/X/g, 'Х');
  return text;
};
