import { takeEvery, takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_PAYMENT_LIST, LOAD_PAYMENT_METHOD_LIST, LOAD_PAYMENT_TYPE_LIST } from '../actions';

import loadPaymentTypeList from './loadPaymentTypeList';
import loadPaymentList from './loadPaymentList';
import loadPaymentMethodList from './loadPaymentMethodList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_PAYMENT_LIST, loadPaymentList);
  yield takeEvery(MODULE_NAME + LOAD_PAYMENT_TYPE_LIST, loadPaymentTypeList);
  yield takeEvery(MODULE_NAME + LOAD_PAYMENT_METHOD_LIST, loadPaymentMethodList);
}
