import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import RadioButton from '../../../components/fields/RadioButton/RadioButton';
import { statusNotificationFormatter } from '../../../utils/formatters';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';

export default function EventLogFilterForm(props) {
  const navigate = useNavigate();
  const {
    onHide, getValues, control, reset, defaultValues, eventListMeta, eventStatusOptions,
  } = props;

  const handleRemoveParams = () => {
    const cleanUrl = window.location.pathname;
    navigate(cleanUrl, { replace: true });
  };

  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    const a = new window.IScroll('#filter-scroll-wrapper', {
      scrollbars: true,
      mouseWheel: true,
      preventDefault: false,
      interactiveScrollbars: true,
    });
    setScroll(a);
  }, []);

  const clear = () => {
    reset({ ...getValues(), ...defaultValues });
    handleRemoveParams();
  };

  const save = () => {
    localStorage.setItem('elp-filter', JSON.stringify({ ...getValues() }));
    onHide();
  };

  return (
    <Dialog
      className={classNames('full-filter')}
      onClose={save}
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Фильтры</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
      )}
      />
      <DialogContent className="hff-content">
        <div className="scroll-wrapper filter-scroll-wrapper" id="filter-scroll-wrapper">
          <div className="scroll">
            <form>
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'host_name',
                  type: String,
                  validators: [],
                  labelKey: 'elp.hostName',
                }}
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                placeholder="Начните печатать для поиска"
                dtoObject={{
                  name: 'client_id',
                  type: String,
                  validators: [],
                  labelKey: 'elp.client_id',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'session_ticket_number',
                  type: String,
                  validators: [],
                  labelKey: 'elp.couponNumber',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'session_id',
                  type: String,
                  validators: [],
                  labelKey: 'elp.sessionNumber',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={RadioButton}
                dtoObject={{
                  name: 'error_level',
                  type: Number,
                  validators: [],
                  labelKey: 'elp.status',
                }}
                className="filter-margin"
                withLabel
                addPlaceholder
                options={eventStatusOptions}
                formatter={statusNotificationFormatter}
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                placeholder="Начните печатать для поиска"
                dtoObject={{
                  name: 'description',
                  type: String,
                  validators: [],
                  labelKey: 'elp.event',
                }}
                className="filter-margin"
              />
              <div className="empty" />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <div className="hff-footer-text">Найдено {eventListMeta && eventListMeta.total ? eventListMeta.total : 0} событий</div>
        <div className="hff-footer-buttons">
          <Button className="hff-clear-show" onClick={save}>Показать</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={clear}>Сбросить всё</Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
