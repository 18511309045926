import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  visualFormatter,
  HostNameAndStatusFormatter,
} from '../../../utils/formatters';
import EventLogGrid from '../../EventLog/components/EventLogGrid';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import { eventLogColumns } from '../../EventLog/constants';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import HostHistoryPopUp from './HostHistoryPopUp';
import { getPaginationOpts, responseLogWithNotification, useUpdatePeriod } from '../../../utils/utils';
import { loadHostActivities } from '../actions';
import { getHostData } from '../reducer';
import { getSystemData } from '../../System/reducer';
import Loader from '../../../components/base/Loader/Loader';

export default function HostStatus(props) {
  const { selectedHost } = props;
  const [isPopup, setIsPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hostActivitiesList, hostActivitiesListMeta, loadingHostActivitiesList } = useSelector(getHostData);
  const { updatePeriod } = useSelector(getSystemData);

  const loadData = opts => {
    const paginationOpts = getPaginationOpts(hostActivitiesListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { host_id: selectedHost.id },
    };
    return dispatch(loadHostActivities(requestData)).catch(responseLogWithNotification);
  };

  const [setPeriod, destroy] = useUpdatePeriod(() => {
    const p1 = loadData();
    return Promise.all([p1]);
  }, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  return (
    <div className="host-status">
      <div className="host-status-info ">
        <div className="host-status-box">
          <div className="host-status-box-panel">
            <HostNameAndStatusFormatter item={selectedHost} />
            <div className="host-status-box-control">
              <div className="control-button" onClick={() => setIsPopup(true)}>
                <Icons width={24} height={24} customIcon icon="history" className="button-icons item-icon" />
                История
              </div>
              {/* <TooltipWithPortal
                showByClick={() => {}}
                content={
                  (
                    <div className="control-button command">
                      <Icons width={18} height={12} customIcon icon="vector" className="button-icons item-icon" />
                      Команды
                    </div>
                  )
                }
                classNamePortal="mpf-more-actions setting-host-status-history-portal"
                tooltipContent={(
                  <div className="hosts-more-wrapper">
                    {item.available_commands && item.available_commands.map(copm => (
                      <Button variant="link" key={copm} className="w-100 hosts-more-button">
                        <div className="hosts-more-button-icon">
                          <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                        </div>
                        {copm}
                      </Button>
                    ))}
                  </div>
                )}
              /> */}
            </div>
          </div>
          <div className="host-status-items">
            <div className="host-status-item visual" key="visual">
              {visualFormatter((selectedHost.vehicle_detection_state))}
            </div>
            {
              selectedHost.components && selectedHost.components.filter(i => !!i.is_active).map((comp, index) => (
                <div className="host-status-item" key={index}>
                  <div className="item-content">
                    <div className="item-title">
                      <span className="item-label">{comp.type_label}</span>
                      <div className="item-equipment-container">
                        <div className="equipments-group-item">
                          <Icons width={32} height={32} customIcon icon={comp.type} className="button-icons" />
                          <div className="equipments-group-item-status">
                            { comp.error_level === 'ok' ? null : <Icons width={17} height={17} customIcon icon={comp.error_level === 'error' ? 'error-circle' : 'warning-circle'} className="button-icons" />}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* { equipment.name === 'cam' ? vehicleRegistrationPlateFormatter(item.vehicleRegistrationPlate) : null } */}
                    <div className="item-description">
                      {comp && comp.last_event && comp.last_event.description ? comp.last_event.description : null}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="host-status-event">
        <div className="host-status-event-panel">
          <div className="host-status-title">Журнал событий</div>
          <div className="host-status-show-table" onClick={() => navigate(`/event-log?host_name=${encodeURIComponent(selectedHost.name)}`)}>Показать всё</div>
        </div>
        <EventLogGrid loadData={loadData} meta={hostActivitiesListMeta} data={hostActivitiesList} columns={getColumnsForTable(eventLogColumns, ['created_at', 'client_id', 'session_ticket_number', 'session_id', 'error_level', 'description'])} />
      </div>
      { isPopup ? <HostHistoryPopUp selectedHost={selectedHost} onHide={() => { setIsPopup(false); }} /> : null}
    </div>
  );
}
