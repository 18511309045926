import { handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { CLIENTS_MODULE } from '../../configs/moduleNames';
import { LOAD_CLIENT_LIST, LOAD_CLIENT_TYPE_LIST } from './actions';

const initialState = {
  clientList: [],
  clientTypeList: [],
  clientListMeta: [],
  loadingRemoveUser: false,
  loadingClientList: false,
  loadingClientTypeList: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(CLIENTS_MODULE + LOAD_CLIENT_LIST, 'clientList', 'loadingClientList'),
  ...createLoadListReducerWithBegin(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST, 'clientTypeList', 'loadingClientTypeList'),
}, initialState);

export default reducer;

export const getClientData = createSelector(
  [state => state[CLIENTS_MODULE]],
  state => {
    const {
      clientList,
      clientTypeList,
      clientListMeta,
      loadingClientList,
      loadingClientTypeList,
    } = state;

    return {
      clientList,
      clientTypeList,
      clientListMeta,
      loadingClientList,
      loadingClientTypeList,
    };
  },
);
