import { call, put } from 'redux-saga/effects';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import { BACKEND_URL } from '../../../configs';
import { clientLoginFailed, clientLogoutSuccessful } from '../actions';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import processError from '../../../utils/processError';

export default function* clientLogout(action) {
  try {
    const url = `${BACKEND_URL}/auth/logout`;
    const pool = {};
    const response = yield makeRequest(url, pool, action, 'GET');
    if (response) {
      yield put(clientLogoutSuccessful());
      yield call(resolveActionPromise(action));
    } else {
      const responseJson = yield response.json();
      yield put(clientLoginFailed());
      yield call(rejectActionPromise(action, responseJson));
    }
  } catch (e) {
    yield processError(e);
  }
}
