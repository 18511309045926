import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { intlShape } from '../components/base/IntlProviderWrapper';
import { Button, Icons } from '../components/base/BootstrapComponents';
import VehicleRegistrationPlate from '../components/base/VehicleRegistrationPlate/VehicleRegistrationPlate';
import TooltipWithPortal from '../components/base/Tooltip/TooltipWithPortal';
import Dropdown from '../components/base/Dropdown/Dropdown';

export function HostNameAndStatusFormatter({ item, isComponentBase }) {
  const anchorElement = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="host">
      {/* <div className="host-assembly">
        { isComponentBase ? (
          <div className="working-state-helper">
            <Dropdown
              usePortal
              customControl
              customOpen={isOpen}
              customHandleClose={() => { setOpen(false); }}
            >
              <Dropdown.Toggle>
                <div
                  className={classNames('tooltip-content')}
                  ref={anchorElement}
                >
                  <div className="host-assembly-icon" ref={anchorElement}>
                    <Icons width={20} height={20} customIcon icon="assembly" className="button-icons" />
                    <div className="field-click-ext" onClick={e => { e.stopPropagation(); setOpen(true); }} />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={classNames('portal-tooltip-wrapper wsh-portal-tooltip-wrapper')}
                anchorElement={anchorElement}
                classNamePortal="portal-tooltip wsh-portal"
                useResizeContent={false}
              >
                <div
                  className="portal-tooltip-popup"
                >
                  <div className="wsh-info-tooltip">
                    <div className="wsh-info-title">
                      Компонентная база
                      <Icons icon="close" customIcon className="close" onClick={e => { e.stopPropagation(); setOpen(false); }} />
                    </div>
                    <table className="wsh-info-table">
                      <tbody>
                        {item.components.length && item.components.map((item, key) => (
                          <tr className="wsh-info-table-row" key={key}>
                            <td className="wsh-info-table-cell wsh-key">{item.type_label}</td>
                            <td className="wsh-info-table-cell wsh-value">{item.description || 'Нет данных'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null }
      </div> */}
      <span className="host-name">
        <div className={classNames('host-status-circle', { online: item.is_online })} />
        {item.name}
      </span>
      <div className="host-path-type">
        <span className="host-type">{(item.from_parking_zone && item.from_parking_zone.name) || 'Улица'}</span>
        <Icons width={17} height={6} customIcon icon="arrow_path" className="button-icons" />
        <span className="host-type">{(item.to_parking_zone && item.to_parking_zone.name) || 'Улица'}</span>
      </div>
    </div>
  );
}

export function EquipmentFormatter(components = []) {
  return (
    <div className="equipments-group">
      {(components || []).map((icon, index) => (
        <div className="equipments-group-item" key={index}>
          <Icons width={32} height={32} customIcon icon={icon.type} className="button-icons" />
          <div className="equipments-group-item-status">
            { icon.error_level === 'ok' ? null : <Icons width={17} height={17} customIcon icon={icon.error_level === 'error' ? 'error-circle' : 'warning-circle'} className="button-icons" />}
          </div>
        </div>
      ))}
    </div>
  );
}

export const eventFormatter = item => (
  <div className="event-formatter">
    <TooltipWithPortal classNameDropDown="event-formatter-portal-tooltip" content={item} />
  </div>
);

export const hostStatusFormatter = status => (
  <div className={classNames('host-status-formatter', { isError: status === 'error' }, { isOff: status === 'off' })}>
    { status }
  </div>
);

export const historyStatusFormatter = status => {
  const {
    formatMessage,
  } = intlShape;
  return (
    <div className="history-status-formatter">
      <div
        className={classNames('history-status-formatter-info', { isError: status === 'error' }, { isWait: status === 'canceled' })}
      >
        {formatMessage({ id: `history.status.formatter.${status}`, defaultMessage: 'Не известно' })}
      </div>
    </div>
  );
};

export const visualFormatter = item => {
  const visualIcon = [
    { value: 'barrier_closed_no_car', icon: 'radar-state-2' },
    { value: 'barrier_closed_car_present', icon: 'radar-state-3' },
    { value: 'barrier_closed_car_departed', icon: 'radar-state-4' },
    { value: 'barrier_opened_no_car', icon: 'radar-state-7' },
    { value: 'barrier_opened_car_present', icon: 'radar-state-5' },
    { value: 'barrier_opened_car_passed', icon: 'radar-state-1' },
    { value: 'barrier_opened_car_departed', icon: 'radar-state-8' },
    { value: 'unavailable', icon: 'radar-state-6' },
  ];
  const resutlt = visualIcon.find(e => e.value === item) || 0;

  return (
    <div className="visual-park">
      { resutlt ? <Icons width={137} height={104} customIcon icon={resutlt.icon} className="button-icons" /> : null }
    </div>
  );
};

export const vehicleRegistrationPlateFormatter = item => (<VehicleRegistrationPlate item={item} />);

export const vehicleRegistrationPlateTextFormatter = item => (
  <div>
    {item.lpn}
  </div>
);

export const logFormatter = (log, item) => (
  <div className="log">
    {item && item.created_at ? <span className="log-time">{dateOrDateTimeFormatter(item.created_at)}</span> : null}
    <span className="log-data">{log || ''}</span>
    {/* { item.variant === 1 ? <span className="log-amount">Сумма: {amount}</span> : null } */}
  </div>
);

export const controlBoxFormatter = item => (
  <div className="control-box-formatter">
    {item.map(icon => (
      <div className="control-box-button" key={icon} onClick={e => e.stopPropagation()}>
        <Icons width={28} height={28} customIcon icon={icon} className="button-icons" />
        <div className="expanded-click-zone" onClick={e => { e.stopPropagation(); }} />
      </div>
    ))}
  </div>
);

export const optionClassFormatter = item => {
  let status = item;
  if (typeof item === 'object') {
    status = item.value;
  }
  if (typeof item !== 'undefined') {
    if (+status === 1) {
      return 'good-state';
    }
    if (+status === 0) {
      return 'warning-state';
    }
    if (+status === -1) {
      return 'error-state';
    }
  }
  return 'unknown-state';
};

export const hostNameFormatter = item => (
  <span className="host-name-formatter">{item}</span>
);

export const sessionNumberFormatter = e => (
  <span className="session-number-formatter">{e}</span>
);

export const clientTypeFormatter = item => (
  <div className="client-type-formatter">
    <div className="client-type-formatter-box">
      {item}
    </div>
  </div>
);

export const clientStatusFormatter = item => (
  <div className={classNames('client-status-formatter')}>
    <div className={classNames('client-status-formatter-box', { warning: item === 'active_outside', error: item === 'blocked' })}>
      { item === 'active_outside' ? 'Активен, вне территории' : item === 'blocked' ? 'Заблокирован' : 'Активен, на территории' }
    </div>
  </div>
);

export const serviceStatusFormatter = item => (
  <div className={classNames('service-status-formatter', { warning: item === 2, error: item === -1 })}>
    <div className={classNames('service-status-formatter-box', { warning: item === 2, error: item === -1 })}>
      { item === 1 ? 'Активен' : item === -1 ? 'Деактивирован' : 'Стоп' }
    </div>
  </div>
);

export const sessionStatusFormatter = item => (
  <div className="session-status-formatter">
    <div className={classNames('session-status-formatter-box', { sucssesful: item === 'in_progress' })}>
      { item === 'complete' ? 'Завершена' : 'Активна' }
    </div>
  </div>
);

export const statusFormatter = status => (
  <div>
    { status === 'ok' ? <Icons width={24} height={24} customIcon icon="successful" className="button-icons" /> : <Icons width={24} height={24} customIcon icon={status === 'error' ? 'error' : 'warning'} className="button-icons" />}
  </div>
);

export const statusNotificationFormatter = status => (
  <div className={classNames('status-notification-formatter', { warning: status === 'warning', error: status === 'error' })}>
    { status === 'ok' ? <Icons width={20} height={20} customIcon icon="successful" className="button-icons" /> : <Icons width={20} height={20} customIcon icon={status === 'error' ? 'error' : 'warning'} className="button-icons" />}
    <span className="status-notification-formatter-text"> { status === 'ok' ? 'Выполнено' : status === 'warning' ? 'Предупреждение' : 'Ошибка'}</span>
  </div>
);

export const dateFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  if (typeof a === 'string') {
    return a;
  }
  return formatDate(a, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const timeFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

export const timeHMFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const dateTimeFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).replace(',', ' ');
};

export function dateOrDateTimeFormatter(date, onlyHour = false, onlyDate = false) {
  if (!date) {
    return '';
  }
  const currentDay = moment().tz('Europe/Moscow').day();
  const d = moment(date).tz('Europe/Moscow');
  const dateDay = moment(d).day();
  if (onlyHour) {
    return d ? d.format('HH:mm') : null;
  }
  if (onlyDate) {
    return d ? d.format('DD.MM.YY') : null;
  }
  return d ? d.format(dateDay === currentDay ? 'HH:mm' : 'DD.MM.YY HH:mm') : null;
}

export function MoreFormatter(isDisable) {
  return !isDisable ? <Icons customIcon icon="dot" className="more-vert" /> : null;
}
