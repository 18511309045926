import { handleActions } from 'redux-actions';

import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { EVENT_LOG_PAGE_MODULE, PAYMENT_LOG_PAGE_MODULE, ZONES_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_PAYMENT_LIST, LOAD_PAYMENT_TYPE_LIST, LOAD_PAYMENT_METHOD_LIST,
} from './actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';

const initialState = {
  paymentList: [],
  paymentListMeta: [],
  paymentTypeList: [],
  paymentMethodList: [],
  loadingPaymentList: false,
  loadingPaymentTypeList: false,
  loadingPaymentMethodList: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_LIST, 'paymentList', 'loadingPaymentList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_TYPE_LIST, 'paymentTypeList', 'loadingPaymentTypeList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_METHOD_LIST, 'paymentMethodList', 'loadingPaymentMethodList'),
}, initialState);

export default reducer;

export const getPaymentData = createSelector(
  [state => state[PAYMENT_LOG_PAGE_MODULE]],
  state => {
    const {
      paymentList,
      paymentListMeta,
      paymentTypeList,
      paymentMethodList,
      loadingPaymentList,
      loadingPaymentTypeList,
      loadingPaymentMethodList,
    } = state;

    return {
      paymentList,
      paymentTypeList,
      paymentMethodList,
      paymentListMeta,
      loadingPaymentList,
      loadingPaymentTypeList,
      loadingPaymentMethodList,
    };
  },
);
