import { takeLatest } from 'redux-saga/effects';
import { LOAD_ZONE_LIST } from '../actions';
import { UPDATE } from '../../../configs/actionNames';

import loadZoneList from './loadZoneList';
import loadZoneStatusList from './loadZoneStatusList';
import { ZONES_PAGE_MODULE } from '../../../configs/moduleNames';
import putZoneById from './putZoneById';

export default function* sagas() {
  yield takeLatest(ZONES_PAGE_MODULE + LOAD_ZONE_LIST, loadZoneList);
  yield takeLatest(ZONES_PAGE_MODULE + UPDATE, putZoneById);
}
