import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import RadioButton from '../../../components/fields/RadioButton/RadioButton';
import DatePicker from '../../../components/fields/DatePicker/DatePicker';

import {
  clientStatusFormatter,
} from '../../../utils/formatters';
import { statusOptions } from '../constants';
import { getZoneData } from '../../Zones/reducer';
import { getClientData } from '../reducer';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';

export default function ClientFilterForm(props) {
  const {
    onHide, getValues, control, reset, defaultValues, meta,
  } = props;
  const [scroll, setScroll] = useState(null);
  const { zoneList } = useSelector(getZoneData);
  const { clientTypeList } = useSelector(getClientData);
  const zoneOptions = zoneList.map(zone => ({ name: zone.name, value: zone.id }));
  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));
  useEffect(() => {
    const a = new window.IScroll('#filter-scroll-wrapper', {
      scrollbars: true,
      mouseWheel: true,
      preventDefault: false,
      interactiveScrollbars: true,
    });
    setScroll(a);
  }, []);

  const clear = () => {
    reset({ ...getValues(), ...defaultValues });
  };

  const save = () => {
    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
    onHide();
  };

  return (
    <Dialog
      className={classNames('full-filter')}
      onClose={save}
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Фильтры</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
      )}
      />
      <DialogContent className="hff-content">
        <div className="scroll-wrapper filter-scroll-wrapper" id="filter-scroll-wrapper">
          <div className="scroll">
            <form>
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                placeholder="Введите ИД клиента"
                dtoObject={{
                  name: 'id',
                  type: String,
                  validators: [],
                  labelKey: 'client.client_id',
                }}
              />
              <FieldDTO
                control={control}
                type="text"
                dtoObject={{
                  name: 'type',
                  type: String,
                  validators: [],
                  labelKey: 'client.clientType',
                }}
                withLabel
                addPlaceholder
                component={SelectInput}
                options={clientTypeOptions}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInputWithLabel}
                placeholder="Введите номер ГРЗ"
                dtoObject={{
                  name: 'lpn',
                  type: String,
                  validators: [],
                  labelKey: 'client.lpn',
                }}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                type="text"
                dtoObject={{
                  name: 'parking_zone_id',
                  type: String,
                  validators: [],
                  labelKey: 'client.zone',
                }}
                withLabel
                addPlaceholder
                component={SelectInput}
                options={zoneOptions}
                className="filter-margin"
              />
              <FieldDTO
                control={control}
                component={DatePicker}
                hideSmallButtons
                showArrowIcon
                withLabel
                label="Срок действия с"
                onChangeToIso
                dtoObject={{
                  name: 'issue_at',
                  type: String,
                  validators: [],
                  labelKey: 'client.dateStart',
                }}
                usePortal
                className="no-border filter-margin"
              />
              <FieldDTO
                control={control}
                component={DatePicker}
                hideSmallButtons
                showArrowIcon
                withLabel
                label="Срок действия по"
                className="no-border filter-margin"
                onChangeToIso
                dtoObject={{
                  name: 'expiry_at',
                  type: String,
                  validators: [],
                  labelKey: 'client.expiry_at',
                }}
                usePortal
              />
              <FieldDTO
                control={control}
                type="text"
                component={RadioButton}
                dtoObject={{
                  name: 'status',
                  type: Number,
                  validators: [],
                  labelKey: 'elp.status',
                }}
                className="filter-margin"
                withLabel
                addPlaceholder
                options={statusOptions}
                formatter={clientStatusFormatter}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        {/* <div className="hff-footer-text">Найдено {meta && meta.total ? meta.total : 0} клиентов</div> */}
        <div className="hff-footer-buttons">
          <Button className="hff-clear-show" onClick={save}>Показать</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={clear}>Сбросить всё</Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
