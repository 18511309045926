import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import DatePicker from '../../../components/fields/DatePicker/DatePicker';
import CustomButton from '../../../components/base/Button/CustomButton';
import ClientCardInfo from './ClientCardInfo';
import { clientStatusFormatter } from '../../../utils/formatters';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import { lpnTypeOptions, statusOptions, vehicleTypeOptions } from '../constants';
import { getZoneData } from '../../Zones/reducer';
import { getClientData } from '../reducer';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { createUser, loadClientList, updateUser } from '../actions';
import notificationDecorator from '../../Notifications/notificationDecorator';
import { responseLogWithNotification } from '../../../utils/utils';
import Switches from '../../../components/fields/Switches/Switches';

export default function ClientForm(props) {
  const {
    onHide = () => {},
    selectedItem = null,
    mode,
    loadData,
  } = props;
  const dispatch = useDispatch();
  const { zoneList } = useSelector(getZoneData);
  const { clientTypeList } = useSelector(getClientData);
  const zoneOptions = zoneList.map(zone => ({ name: zone.name, value: zone.id }));
  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));
  const initial = {};
  if (selectedItem) {
    initial.id = selectedItem.id;
    initial.masked_pan = selectedItem.masked_pan;
    initial.lpn_type = selectedItem.lpn_type;
    initial.vehicle_type = selectedItem.vehicle_type;
    initial.type = selectedItem.type;
    initial.lpn = selectedItem.lpn;
    initial.parking_zone_id = selectedItem.parking_zone_id;
    initial.issue_at = selectedItem.issue_at || null;
    initial.expiry_at = selectedItem.expiry_at || null;
    initial.status = selectedItem.status;
    initial.description = selectedItem.description;
    initial.is_active = selectedItem.is_active;
  }
  const {
    control, getValues, watch, setValue,
  } = useForm({
    defaultValues: initial,
  });

  const updateClient = () => {
    const data = getValues();
    const action = mode === 'edit' ? updateUser({ id: data.id, userData: data }) : createUser({ userData: data });
    dispatch(action)
      .then(() => {
        notificationDecorator({
          title: 'Редактирование клиента',
          message: 'Редактирование клиента успешно',
        });
        onHide();
        loadData();
      })
      .catch(responseLogWithNotification);
  };

  return (
    <div className="client-form">
      <span className="client-form-title">Основная информация</span>
      <div className="client-form-data">
        <div className="client-form-data-box">
          { mode === 'edit' ? (
            <FieldDTO
              control={control}
              type="text"
              className="short"
              disabled
              component={TextInputWithLabel}
              dtoObject={{
                name: 'id',
                type: String,
                validators: [],
                labelKey: 'client.client_id',
              }}
            />
          ) : null}
          <FieldDTO
            control={control}
            type="text"
            className="short"
            dtoObject={{
              name: 'type',
              type: String,
              validators: [],
              labelKey: 'client.clientType',
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={clientTypeOptions}
          />
          <FieldDTO
            control={control}
            type="text"
            className="short"
            dtoObject={{
              name: 'parking_zone_id',
              type: String,
              validators: [],
              labelKey: 'client.zone',
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={zoneOptions}
          />
          <FieldDTO
            control={control}
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'lpn',
              type: String,
              validators: [],
              labelKey: 'client.lpn',
            }}
          />
          <FieldDTO
            control={control}
            type="text"
            className="short"
            dtoObject={{
              name: 'lpn_type',
              type: String,
              validators: [],
              labelKey: 'client.lpn_type',
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={lpnTypeOptions}
          />
          <FieldDTO
            control={control}
            component={DatePicker}
            hideSmallButtons
            showArrowIcon
            withLabel
            label="Срок действия с"
            dtoObject={{
              name: 'issue_at',
              type: String,
              validators: [],
              labelKey: 'client.dateStart',
            }}
            usePortal
            className="short"
            onChangeToIso
          />
          <FieldDTO
            control={control}
            component={DatePicker}
            hideSmallButtons
            showArrowIcon
            usePortal
            withLabel
            // withFormat="YYYY-MM-DDTHH:mm:ssZ"
            label="Срок действия по"
            onChangeToIso
            dtoObject={{
              name: 'expiry_at',
              type: String,
              validators: [],
              labelKey: 'client.dateEnd',
            }}
            className="short"
          />
          <FieldDTO
            control={control}
            type="text"
            className="short"
            dtoObject={{
              name: 'vehicle_type',
              type: String,
              validators: [],
              labelKey: 'client.vehicle_type',
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={vehicleTypeOptions}
          />
          <FieldDTO
            control={control}
            type="text"
            component={Switches}
            dtoObject={{
              name: 'is_active',
              type: Number,
              validators: [],
              labelKey: 'elp.is_active',
            }}
            className="filter-margin"
            withLabel
            addPlaceholder
          />
          <FieldDTO
            control={control}
            type="text"
            className={mode === 'create' ? 'short' : ''}
            component={TextInputWithLabel}
            dtoObject={{
              name: 'description',
              type: String,
              validators: [],
              labelKey: 'client.description',
            }}
          />
          <div className="empty" />
        </div>
        { selectedItem && mode === 'edit' ? (
          <div className="client-form-data-card-wrapper">
            <ClientCardInfo selectedItem={selectedItem} />
            {/* <div className="client-form-data-controls">
              <CustomButton isCancel text="Распечатать" className="extend" />
            </div> */}
          </div>
        ) : null }
      </div>
      <div className="client-form-control">
        <CustomButton text="Сохранить" onClick={updateClient} />
        <CustomButton isCancel text="Закрыть" onClick={onHide} />
      </div>
    </div>
  );
}
