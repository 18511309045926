import { takeLatest, takeEvery } from 'redux-saga/effects';
import { CLIENTS_MODULE } from '../../../configs/moduleNames';
import { LOAD_BY_ID } from '../../../configs/actionNames';
import {
  LOAD_CLIENT_LIST,
  DELETE_USER,
  UPDATE_USER,
  CREATE_USER, LOAD_CLIENT_TYPE_LIST,
} from '../actions';
import loadClientList from './loadClientList';
import deleteUser from './deleteUser';
import updateUser from './updateUser';
import createUser from './createUser';
import loadClientTypeList from './loadClientTypeList';
import loadClientById from './loadClientById';

export default function* sagas() {
  yield takeLatest(CLIENTS_MODULE + LOAD_CLIENT_LIST, loadClientList);
  yield takeLatest(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST, loadClientTypeList);
  yield takeEvery(CLIENTS_MODULE + DELETE_USER, deleteUser);
  yield takeEvery(CLIENTS_MODULE + UPDATE_USER, updateUser);
  yield takeEvery(CLIENTS_MODULE + CREATE_USER, createUser);
  yield takeEvery(CLIENTS_MODULE + LOAD_BY_ID, loadClientById);
}
