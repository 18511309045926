import { put, call, delay } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL, DEFAULT_ITEMS_OF_PAGE, LOADING_DELAY } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadHostComponentsTypeFailed,
  loadHostComponentsTypeSuccessful,
  loadHostComponentsTypeBegin,
} from '../actions';

export default function* loadHostComponentsType(action) {
  try {
    const { payload = {} } = action;
    const {
      limit = DEFAULT_ITEMS_OF_PAGE,
      page = 1,
    } = payload;
    const pool = {
      limit,
      page,
    };
    const url = `${BACKEND_URL}/component-types`;
    yield put(loadHostComponentsTypeBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadHostComponentsTypeSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadHostComponentsTypeFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadHostComponentsTypeFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
