import React, { useState, useEffect } from 'react';
import './zones-page.scss';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { loadZoneList } from '../actions';
import { getPaginationOpts, responseLogWithNotification, useUpdatePeriod } from '../../../utils/utils';
import { getZoneData } from '../reducer';

import PageHeader from '../../../components/base/PageHeader/PageHeader';
import GridZoneCards from './GridZoneCards';
import Loader from '../../../components/base/Loader/Loader';
import { getSystemData } from '../../System/reducer';

export default function ZonesPage() {
  const dispatch = useDispatch();
  const { updatePeriod } = useSelector(getSystemData);
  const { loadingZoneList, zoneList, zoneListMeta } = useSelector(getZoneData);

  const loadData = opts => {
    const p1 = dispatch(loadZoneList()).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  return (
    <div className="zones-page">
      <div className="zones-page-wrapper">
        <PageHeader header="Список зон">
          <div className="header-loader"><Loader loading={loadingZoneList} /></div>
        </PageHeader>
        <GridZoneCards loadData={loadData} zones={zoneList} />
      </div>
    </div>
  );
}
