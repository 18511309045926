import {
  sessionNumberFormatter,
  sessionStatusFormatter,
  dateOrDateTimeFormatter,
} from '../../utils/formatters';

export const MODULE_NAME = 'SESSION_LOG_PAGE';
export const sessionLogColumns = [
  {
    dataField: 'id',
    text: 'Номер сессии',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    formatter: sessionNumberFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'client_id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    hidden: true,
  },
  {
    dataField: 'parking_zone',
    text: 'Зона клиента',
    formatter: e => e && e.name,
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-3',
    hidden: true,
  },
  {
    dataField: 'client',
    text: 'Тип клиента',
    disableSort: true,
    formatter: e => e && e.type_label,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    // formatter: clientTypeFormatter,
    hidden: true,
  },
  {
    dataField: 'started_at',
    text: 'Дата и время начала',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'ended_at',
    text: 'Дата и время окончания',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    formatter: sessionStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'updated_at',
    text: 'Последняя операция',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'client',
    text: 'Номер ГРЗ',
    formatter: date => date && date.lpn,
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-9',
    hidden: true,
  },
  {
    dataField: 'ticket_number',
    text: 'Номер талона',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-10',
    hidden: true,
  },
  {
    dataField: 'total_paid',
    text: 'Сумма оплаты, руб',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-11',
    hidden: true,
  },
];
