import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './hosts-directory-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import { defaultFilterValues } from './moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import HostsDirectoryGrid from './HostsDirectoryGrid';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import HostAddPopup from './HostAddPopup';
import HostSettingPopup from './HostSettingPopup';
import {
  responseLogWithNotification,
  getPaginationOpts,
  getJsonFromStorage,
  useUpdatePeriod,
} from '../../../utils/utils';
import CustomButton from '../../../components/base/Button/CustomButton';
import HostDeletePopUp from './HostDeletePopUp';
import { getSystemData } from '../../System/reducer';
import { getHostData } from '../../Hosts/reducer';
import {
  deleteHost,
  loadHostList,
  loadHostTypeList,
  loadHostComponentsType,
  createHost,
  updateHost,
} from '../../Hosts/actions';
import { loadZoneList } from '../../Zones/actions';
import { loadClientTypeList } from '../../Clients/actions';
import Loader from '../../../components/base/Loader/Loader';
import notificationDecorator from '../../Notifications/notificationDecorator';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import HostEditPopup from './HostEditPopup';

export default function HostsDirectoryPage() {
  const dispatch = useDispatch();
  const [components, setComponents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState('');
  const [selectedRow, setSelectedRow] = useState(false);
  const [isAddPopUp, setIsAddPopUp] = useState(false);
  const { updatePeriod } = useSelector(getSystemData);
  const {
    hostList,
    hostListMeta,
    loadingHostList,
    hostTypeList,
    hostComponentsTypeList,
  } = useSelector(getHostData);

  const {
    control, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: getJsonFromStorage('host-directory-filter', { ...defaultFilterValues }) });

  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(hostListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: {
        ...filterData,
        with_active_components: 1,
        with_components: 1,
        with_actions: 1,
        with_parking_zones: 1,
      },
    };
    const p1 = dispatch(loadHostList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const removeHostByIds = user_ids => {
    const promises = user_ids.map(id => dispatch(deleteHost({ id })));
    loadData();
    return Promise.all(promises).then(() => {
      setSelected([]);
      notificationDecorator({
        title: 'Удаление хоста',
        message: 'Удаление хоста успешно',
      });
    }).catch(responseLogWithNotification);
  };

  const create = body => {
    dispatch(createHost({ body })).then(() => {
      notificationDecorator({
        title: 'Создание хоста',
        message: 'Создание хоста',
      });
      loadData();
    }).catch(responseLogWithNotification);
  };

  const update = (id, body) => {
    dispatch(updateHost({ id, body })).then(() => {
      notificationDecorator({
        title: 'Обновление хоста',
        message: 'Обновление хоста',
      });
      loadData();
    }).catch(responseLogWithNotification);
  };

  const [setPeriod, clear] = useUpdatePeriod(loadData, updatePeriod);

  useEffect(() => {
    setPeriod(updatePeriod);
    return clear;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => { loadData(); });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(loadZoneList()).catch(responseLogWithNotification);
    dispatch(loadClientTypeList()).catch(responseLogWithNotification);
    dispatch(loadHostComponentsType()).catch(responseLogWithNotification);
    dispatch(loadHostTypeList()).catch(responseLogWithNotification);
  }, []);

  const hostTypeOptions = hostTypeList.map(hostType => ({ name: hostType.label, value: hostType.value }));

  return (
    <div className="hosts-page">
      <div className="hosts-wrapper">
        <div className="hosts-inputs-box">
          <div className={classNames('hosts-info', { variant: selectedRow })}>
            <PageHeader header="Справочник хостов">
              <div className="header-loader"><Loader loading={loadingHostList} /></div>
            </PageHeader>
          </div>
          <div className="hosts-inputs">
            <FieldDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'search',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              control={control}
              className="small no-border search-field "
            />
            <FieldDTO
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'name',
                type: String,
                validators: [],
                labelKey: 'host.hostName',
              }}
              control={control}
              className="small no-border"
            />
            <SeparateBox />
            <IconBox icon="plus-add" onClick={() => setIsAddPopUp(true)} />
            {selected.length ? <CustomButton text="Удалить" onClick={() => { setMode('delete'); }} icon="trash" /> : null}
          </div>
        </div>
        <HostsDirectoryGrid meta={hostListMeta} setMode={setMode} selected={selected} loadData={loadData} setSelected={setSelected} data={hostList} onClickByRow={handlerClickRow} />
        { selected && selected.length && mode === 'delete' ? <HostDeletePopUp setSelected={setSelected} removeHostByIds={removeHostByIds} user_ids={selected} onHide={() => setMode(false)} /> : null }
        { isAddPopUp ? <HostAddPopup components={components} setComponents={setComponents} create={create} hostTypeOptions={hostTypeOptions} hostComponentsTypeList={hostComponentsTypeList} onHide={() => setIsAddPopUp(null)} /> : null }
        {/* { mode === 'edit' ? <HostSettingPopup hostComponentsTypeList={hostComponentsTypeList} onHide={() => { setMode(''); }} selectedRow={selectedRow} /> : null } */}
        { mode === 'edit' ? <HostEditPopup update={update} components={components} hostTypeOptions={hostTypeOptions} setComponents={setComponents} onHide={() => { setMode(''); }} selectedRow={selectedRow} /> : null }
      </div>
    </div>
  );
}
