export const defaultFilterValues = {
  search: '',
  name: '',
  zones: '',
  group: '',
  lpn: '',
  typeClient: '',
  date: '',
  completed_at_to: '',
  completed_at_from: '',
};

export const defaultSettingColumnValues = {
  host: true,
  equipments: true,
  visual: true,
  vehicleRegistrationPlate: true,
  log: true,
  control: true,
  setting: true,
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];
