import React from 'react';
import classNames from 'classnames';
import ZoneCard from './ZoneCard';

export default function GridZoneCards(props) {
  const {
    zones,
    loadData,
  } = props;

  return (
    <div className={classNames('grid-zones')}>
      {zones.map((item, index) => <ZoneCard loadData={loadData} key={index} item={item} isMiniatures={zones.length > 3} />)}
    </div>
  );
}
