import { handleActions } from 'redux-actions';
import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { HOSTS_MODULE, SYSTEM_MODULE } from '../../configs/moduleNames';
import { LOAD_MONITOR_LIST } from './actions';

const initialState = {
  loadingMonitorList: false,
  monitorList: [],
  monitorListMeta: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_MONITOR_LIST, 'monitorList', 'loadingMonitorList'),
}, initialState);

export default reducer;

export const getMonitorData = createSelector(
  [state => state[MODULE_NAME]],
  state => {
    const {
      monitorList,
      monitorListMeta,
      loadingMonitorList,
    } = state;
    return {
      monitorListMeta,
      monitorList,
      loadingMonitorList,
    };
  },
);
