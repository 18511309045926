import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { putZoneFailed, putZoneSuccessful } from '../actions';
import { OK } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* putZoneById(action) {
  try {
    const {
      id,
      date,
    } = action.payload;
    const url = `${BACKEND_URL}/parking-zones/${id}`;
    const dataResponse = yield makeRequest(url, { ...date }, action, 'PUT', true);
    const data = yield dataResponse.json();

    if (dataResponse.status === OK
      && data
    ) {
      yield put(putZoneSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(putZoneFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(putZoneFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
