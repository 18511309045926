import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import './service-monitoring-page.scss';
import {
  responseLogWithNotification,
  getPaginationOpts,
  getJsonFromStorage,
  useUpdatePeriod,
} from '../../../utils/utils';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import ServiceMonitoringGrid from './ServiceMonitoringGrid';
import { loadMonitorList } from '../actions';
import { getMonitorData } from '../reducer';
import { getSystemData } from '../../System/reducer';
import Loader from '../../../components/base/Loader/Loader';

export default function ServiceMonitoringPage() {
  const dispatch = useDispatch();
  const { monitorList, monitorListMeta, loadingMonitorList } = useSelector(getMonitorData);
  const { updatePeriod } = useSelector(getSystemData);

  const defaultFilterValues = { type: '', serviceStatus: '' };
  const { getValues, watch } = useForm({ defaultValues: getJsonFromStorage('sm-filter', { ...defaultFilterValues }) });

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(monitorListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
      // sort: sortData.join(','),
    };
    const p1 = dispatch(loadMonitorList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => { loadData(); });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="service-monitoring-page">
      <div className="sm-container">
        <div className="sm-inputs-box">
          <PageHeader header="Мониторинг сервисов">
            <div className="header-loader"><Loader loading={loadingMonitorList} /></div>
          </PageHeader>
          <div className="sm-inputs">
            {/* <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'type',
                type: String,
                validators: [],
                labelKey: 'sm.service',
              }}
              inputProps={{
                ...register('type', { maxLength: 30 }),
                onChange: e => {
                  setValue('type', e.target.value);
                  localStorage.setItem('sm-filter', JSON.stringify({ ...getValues() }));
                },
                value: getValues('type') || '',
              }}
              component={CustomSelectInput}
              options={serviceOptions}
              className="no-border select-field"
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'serviceStatus',
                type: String,
                validators: [],
                labelKey: 'sm.serviceStatus',
              }}
              inputProps={{
                ...register('serviceStatus', { maxLength: 30 }),
                onChange: e => {
                  setValue('serviceStatus', e.target.value);
                  localStorage.setItem('sm-filter', JSON.stringify({ ...getValues() }));
                },
                value: Number(getValues('serviceStatus')) || '',
              }}
              component={CustomSelectInput}
              options={serviceStatusOptions}
              className="no-border select-field"
            /> */}
            {/* <SeparateBox />
            <IconBox icon="export" /> */}
          </div>
        </div>
        <ServiceMonitoringGrid data={monitorList} meta={monitorListMeta} loadData={loadData} />
      </div>
    </div>
  );
}
