export const LOCAL_STORAGE_LOCALE = 'locale';
export const LOCAL_STORAGE_CLIENT_KEY = 'client';
export const LOCAL_STORAGE_UPDATE_PERIOD = 'update-period';
export const LOCAL_STORAGE_CLIENT_SESSION_KEY = 'session';
export const LOCAL_STORAGE_CLIENT_SESSION_TIMEOUT = 'stm';
export const FILTER_FORM = 'FILTER_FORM';
export const CREATE_FORM = 'CREATE_FORM';

export const RESPONSE_ERROR_CODE = {
  USER_IS_ALREADY_REGISTERED: 'USER_IS_ALREADY_REGISTERED',
  USER_IS_BLOCKED: 'USER_IS_BLOCKED',
  USER_IS_NOT_FOUND: 'USER_IS_NOT_FOUND',
  ALREADY_ACCEPTED: 'ALREADY_ACCEPTED',
  ALREADY_ARCHIVED: 'ALREADY_ARCHIVED',
  ALREADY_REJECTED: 'ALREADY_REJECTED',
  ASSIGNED_OTHER_USER: 'ASSIGNED_OTHER_USER',
  ALREADY_AUTHORIZED: 'ALREADY_AUTHORIZED',
  BAD_LOGIN_OR_PASSWORD: 'BAD_LOGIN_OR_PASSWORD',
  PASSWORDS_DO_NOT_MATCH: 'PASSWORDS_DO_NOT_MATCH',
  PASSWORD_ALREADY_USED: 'PASSWORD_ALREADY_USED',
  BAD_PASSWORD: 'BAD_PASSWORD',
  ROLE_IS_NOT_FOUND: 'ROLE_IS_NOT_FOUND',
  ROLE_ALREADY_EXISTS: 'ROLE_ALREADY_EXISTS',
  FORBIDDEN: 'FORBIDDEN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  DB_ERROR: 'DB_ERROR',
};
