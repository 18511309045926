import classNames from 'classnames';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import './DropdownStyles.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.menuRef = React.createRef();
    this.dropdownId = `dropdown-${Math.random() * 10000}`;
    this.state = {
      open: false,
    };
    this.callbackMouseDown = this.handleClickOutside.bind(this);
    this.callbackKeyDown = this.keyDown.bind(this);
  }

  componentDidMount() {
    const { setToggleFunction, ignoreScroll = false } = this.props;
    document.addEventListener('mousedown', this.callbackMouseDown);
    window.addEventListener('keydown', this.callbackKeyDown);
    if (!ignoreScroll) {
      window.customEventListener.addEventListener('scroll', this.handleScroll.bind(this), this.dropdownId);
    }
    this.setCallback();
    if (setToggleFunction && typeof setToggleFunction === 'function') {
      setToggleFunction(isOpen => this.setState({ open: isOpen }));
    }
  }

  componentWillUnmount() {
    const { ignoreScroll = false } = this.props;
    document.removeEventListener('mousedown', this.callbackMouseDown.bind(this));
    window.removeEventListener('keydown', this.callbackKeyDown.bind(this));
    if (!ignoreScroll) {
      window.customEventListener.removeEventListener('scroll', this.dropdownId);
    }
  }

  handleScroll(event) {
    const {
      customControl = false,
      customOpen = false,
      customHandleClose = () => {},
    } = this.props;
    if (this.state.open || (customControl && customOpen)) {
      const a = this.wrapperRef && this.wrapperRef.current && this.wrapperRef.current.contains(event.target);
      const b = this.menuRef && this.menuRef.current && this.menuRef.current.contains(event.target);
      if (a || b) {
        return;
      }
      if (customControl && customOpen) {
        return customHandleClose();
      }
      return this.close();
    }
  }

  setCallback = () => {
    const { setCloseFunction } = this.props;
    if (setCloseFunction) {
      setCloseFunction(this.close.bind(this));
    }
  };

  close = () => {
    const { closeCallback = () => {} } = this.props;
    this.setState({ open: false });
    closeCallback();
  };

  keyDown = event => {
    const { open } = this.state;
    if (event.keyCode === 27 && open) {
      this.close();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  setRef = ref => {
    this.wrapperRef.current = ref;
  };

  setMenuRef = ref => {
    this.menuRef.current = ref;
  };

  handleClickOutside = event => {
    const {
      usePortal = false,
      customControl = false,
      customHandleClose = () => {},
    } = this.props;

    const isNotWrapperContains = this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target);
    const isNotMenuContains = this.menuRef && this.menuRef.current && !this.menuRef.current.contains(event.target);
    if (isNotWrapperContains && !usePortal) {
      return customControl ? customHandleClose() : this.close();
    }
    if (isNotWrapperContains && isNotMenuContains) {
      return customControl ? customHandleClose() : this.close();
    }
  };

  toggleOpen = () => {
    const {
      customControl = false,
      disabled = false,
      onToggleOpen = () => {},
    } = this.props;

    if (customControl || disabled) {
      return;
    }

    this.setState({ open: !this.state.open }, () => {
      onToggleOpen(this.state.open);
    });
    return false;
  };

  render() {
    const {
      className = '',
      children,
      dropLeft = false,
      usePortal = false,
      customControl = false,
      customOpen = false,
    } = this.props;
    const { open } = this.state;
    const elements = React.Children.toArray(children).map(child => React.cloneElement(child, {
      open: customControl ? customOpen : open,
      toggleOpen: this.toggleOpen,
      setMenuRef: this.setMenuRef,
      menuRef: this.menuRef,
      usePortal,
    }));
    return (
      <div className={classNames('dropdown', className, { dropleft: dropLeft })} ref={this.setRef}>
        {elements}
      </div>
    );
  }
}

export function DropdownToggle(props) {
  const {
    className = '',
    style = {},
    children,
    open = false,
    toggleOpen = () => {},
    ref,
    target = null,
  } = props;

  const handleToggleOpen = event => {
    const ct = target && target.current ? target.current : target;
    if (ct && ct.contains && !ct.contains(event.target)) {
      return;
    }
    toggleOpen();
  };

  return (
    <div
      style={style}
      className={classNames('dropdown-toggle', className, { active: open })}
      aria-labelledby="dropdownMenuButton"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-expanded={open}
      onClick={handleToggleOpen}
      ref={ref}
    >
      {children}
    </div>
  );
}

export class DropdownMenu extends Component {
  getAnchorDimensions = () => {
    if (!this.props.anchorElement || !this.props.anchorElement.current || !this.props.anchorElement.current.getBoundingClientRect) { return null; }
    return this.props.anchorElement.current.getBoundingClientRect();
  };

  render() {
    const {
      className = '',
      children,
      open = false,
      usePortal = false,
    } = this.props;
    if (usePortal) {
      const anchorDimensions = this.getAnchorDimensions();
      if (!open || !anchorDimensions) {
        return null;
      }
      return <DropdownPortal {...this.props} />;
    }
    return (
      <ul className={classNames('dropdown-menu', className, { show: open })}>
        {children}
      </ul>
    );
  }
}
export class DropdownPortal extends Component {
  state = {
    style: {
      ...this.props.styles,
      left: 0,
      // right: 0,
      top: 0,
      // bottom: 0,
      width: 0,
      height: 0,
    },
    className: '',
  };

  constructor(props) {
    super(props);
    this.menuContentRef = React.createRef();
  }

  componentDidMount() {
    this.calculateStale();
    window.addEventListener('resize', this.calculateStale);
  }

  componentWillUnmount() {
    this.calculateStale();
    window.removeEventListener('resize', this.calculateStale);
  }

  setMenuContentRef = ref => {
    this.menuContentRef.current = ref;
    setTimeout(this.calculateStale, 10);
  };

  calculateStale = () => {
    // const dimensions = this.getInnerDimensions();
    let anchorRect = this.getAnchorDimensions();
    // const bodySize = this.getBodyDimensions();
    const viewportSize = this.getWindowDimensions();
    // const windowScroll = this.getWindowScroll();
    const contentSize = this.getContentSize(anchorRect, viewportSize);
    if (!anchorRect) {
      anchorRect = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
      };
    }

    // return {
    //   anchorSize: anchorRect,
    //   bodySize,
    //   surfaceSize: dimensions,
    //   viewportDistance: {
    //     top: anchorRect.top,
    //     right: viewportSize.width - anchorRect.right,
    //     bottom: viewportSize.height - anchorRect.bottom,
    //     left: anchorRect.left,
    //   },
    //   viewportSize,
    //   windowScroll,
    // };

    const { rightPadding = 0, styles = {} } = this.props;

    let { left, top } = anchorRect;
    if (left + contentSize.width + rightPadding > viewportSize.width) {
      left = anchorRect.right - contentSize.width;
    }
    let className = '';
    let bottom = viewportSize.height - anchorRect.bottom;
    if (anchorRect.bottom + contentSize.height > viewportSize.height) {
      className = 'dropdown-menu-open-top';
      const newTop = anchorRect.top - contentSize.height;
      if (newTop >= 0) {
        bottom = anchorRect.top;
        top = anchorRect.top - contentSize.height;
      } else {
        top = 0;
        bottom = contentSize.height;
      }
    }
    // в момент 1 отрисовки рисуем в скрытом слое для понимания размеров блока. Далее рисуем по логике
    this.setState({
      style: {
        ...styles,
        left: contentSize.height ? left : 0,
        right: viewportSize.width - anchorRect.right,
        top: contentSize.height ? top : 0,
        bottom: contentSize.height ? bottom : viewportSize.height,
        visibility: contentSize.height ? 'visible' : 'hidden',
      },
      className,
    });
  };

  getWindowDimensions = () => ({ width: window.innerWidth, height: window.innerHeight });

  // getBodyDimensions = () => ({
  //   width: document.body.clientWidth,
  //   height: document.body.clientHeight,
  // });
  //
  // getWindowScroll = () => ({ x: window.pageXOffset, y: window.pageYOffset });

  getAnchorDimensions = () => {
    if (!this.props.anchorElement || !this.props.anchorElement.current) { return null; }
    return this.props.anchorElement.current.getBoundingClientRect();
  };

  // getInnerDimensions = () => {
  //   const element = this.props.menuRef.current;
  //   if (!element) { return { width: 0, height: 0 }; }
  //   return { width: element.offsetWidth, height: element.offsetHeight };
  // };

  getContentSize = (anchorRect, viewportSize) => {
    const { useResizeContent = true } = this.props;
    const element = this.menuContentRef.current;
    if (!element || !anchorRect) { return { width: 0, height: 0 }; }
    if (!element.style.width) {
      element.style.width = anchorRect.width;
    }
    let maxHeight = 0;
    if (viewportSize && anchorRect && useResizeContent) {
      maxHeight = Math.max(viewportSize.height, 0) - Math.max(anchorRect.bottom, anchorRect.top) - 16;
      element.style.maxHeight = maxHeight ? `${maxHeight}px` : '100%';
    } else {
      maxHeight = element.offsetHeight;
    }
    return { width: element.offsetWidth, height: Math.min(element.offsetHeight, maxHeight) };
  };

  render() {
    const {
      className = '',
      classNamePortal = '',
      children,
      open = false,
      disableClickToggle = false,
      // usePortal = false,
      setMenuRef,
      onMouseEnter = () => {},
      onMouseLeave = () => {},
      toggleOpen = () => {},
    } = this.props;
    const { style: styles, className: systemClassName } = this.state;
    return ReactDOM.createPortal(
      <div
        className={classNames('dropdown-menu-portal', classNamePortal)}
        style={styles}
        ref={setMenuRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={!disableClickToggle ? toggleOpen : () => {}}
      >
        <ul className={classNames('dropdown-menu', className, systemClassName, { show: open })} ref={this.setMenuContentRef}>
          {children}
        </ul>
      </div>,
      document.body,
    );
  }
}

Dropdown.Menu = DropdownMenu;
Dropdown.Toggle = DropdownToggle;

export default Dropdown;
