import { handleActions } from 'redux-actions';

import { createSelector } from '@reduxjs/toolkit';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { CLIENTS_MODULE, ZONES_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_ZONE_LIST, LOAD_ZONE_STATUS_LIST,
} from './actions';

const initialState = {
  loadingZoneList: false,
  loadingZoneStatusList: false,
  zoneList: [],
  zoneListMeta: [],
  zoneStatusList: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(ZONES_PAGE_MODULE + LOAD_ZONE_LIST, 'zoneList', 'loadingZoneList'),
  ...createLoadListReducerWithBegin(ZONES_PAGE_MODULE + LOAD_ZONE_STATUS_LIST, 'zoneStatusList', 'loadingZoneStatusList'),
}, initialState);

export default reducer;

export const getZoneData = createSelector(
  [state => state[ZONES_PAGE_MODULE]],
  state => {
    const {
      zoneList,
      zoneStatusList,
      zoneListMeta,
      loadingZoneList,
      loadingZoneStatusList,
    } = state;

    return {
      zoneList,
      zoneStatusList,
      zoneListMeta,
      loadingZoneList,
      loadingZoneStatusList,
    };
  },
);
