import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getClientData } from '../reducer';
import './client-page.scss';
import {
  loadClientList,
  deleteUser,
  updateUser,
  createUser, loadClientTypeList, loadClientById,
} from '../actions';
import {
  responseLogWithNotification,
  getPaginationOpts,
  getJsonFromStorage,
  useUpdatePeriod,
} from '../../../utils/utils';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInput from '../../../components/fields/TextInput/TextInput';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import ClientLogGrid from './ClientGrid';
import ClientDetailsPopUp from './ClientDetailsPopUp';
import ClientLogFilterForm from './ClientFilterForm';
import IconBox from '../../../components/common/IconBox';
import CustomButton from '../../../components/base/Button/CustomButton';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import ClientForm from './ClientForm';
import ClientAddPopUp from './ClientAddPopUp';
import ClientDeletePopUp from './ClientDeletePopUp';
import notificationDecorator from '../../Notifications/notificationDecorator';
import ClientStatus from './ClientStatus';
import { getZoneData } from '../../Zones/reducer';
import Loader from '../../../components/base/Loader/Loader';
import { getSystemData } from '../../System/reducer';
import { defaultColumns, defaultFilterValues } from '../constants';
import { loadZoneList } from '../../Zones/actions';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { getPaymentData } from '../../PaymentLog/reducer';
import { getSessionData } from '../../SessionLog/reducer';

export default function ClientPage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState('');
  const [data, setData] = useState('');
  const { updatePeriod } = useSelector(getSystemData);
  const {
    clientList,
    clientTypeList,
    clientListMeta,
    loadingClientList,
  } = useSelector(getClientData);
  const { loadingZoneList } = useSelector(getZoneData);
  const { loadingPaymentList } = useSelector(getPaymentData);
  const { loadingSessionStatus } = useSelector(getSessionData);
  const client_id = searchParams.get('client_id');

  const handlerClickRow = item => {
    setSearchParams({ client_id: item.id });
  };

  const clearSelected = () => {
    setSearchParams({});
    setSelectedRow(null);
  };

  const removeClient = user_ids => event => {
    const promises = user_ids.map(id => dispatch(deleteUser(id)));
    return Promise.all(promises).then(() => {
      loadData();
      setSelected([]);
      notificationDecorator({
        title: 'Удаление клиента',
        message: 'Удаление клиента успешно',
      });
    }).catch(responseLogWithNotification);
  };

  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));

  const {
    control, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: getJsonFromStorage('client-filter', { ...defaultFilterValues }) });

  const loadData = opts => {
    const filterData = getValues();
    const paginationOpts = getPaginationOpts(clientListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const p1 = dispatch(loadClientList(requestData)).catch(responseLogWithNotification);
    return Promise.all([p1]);
  };

  const [setPeriod, destroy] = useUpdatePeriod(loadData, updatePeriod);
  useEffect(() => {
    setPeriod(updatePeriod);
    return destroy;
  }, [updatePeriod]);

  useEffect(() => {
    const subscription = watch(values => {
      loadData();
      localStorage.setItem('client-filter', JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(loadZoneList()).catch(responseLogWithNotification);
    dispatch(loadClientTypeList()).catch(responseLogWithNotification);
  }, []);

  useEffect(() => {
    if (client_id) {
      dispatch(loadClientById(client_id)).then(result => setSelectedRow(result.data)).catch(responseLogWithNotification);
    }
  }, [client_id]);
  const selectedItem = clientList.find(f => selectedRow && (f.id === selectedRow.id)) || null;
  return (
    <div className="client-page">
      <div className="client-container">
        <div className="client-inputs-box">
          <div className={classNames('client-title-page', { variant: mode || data || selectedRow })}>
            {
              mode === 'create' || mode === 'edit' || selectedRow ? (
                <div className="path">
                  <span className="path-main" onClick={() => { setMode(null); clearSelected(); setData(null); }}>Клиенты</span> / <span>{ selectedRow ? 'Просмотр карточки клиента' : mode === 'edit' ? 'Редактирование карточки клиента' : mode === 'create' ? 'Добавление нового клиента' : null}</span>
                </div>
              ) : null
            }
            <PageHeader
              header={selectedRow ? `Просмотр карточки клиента ${selectedRow.id} (ИД)` : mode === 'edit' ? `Редактирование карточки клиента ${data.id}` : mode === 'create' ? 'Добавление нового клиента' : 'Клиенты'}
            >
              <div className="header-loader"><Loader loading={loadingZoneList || loadingClientList || loadingSessionStatus || loadingPaymentList} /></div>
            </PageHeader>
          </div>
          {(mode === 'views' || mode === 'delete' || !mode) && !selectedRow ? (
            <div className="client-inputs">
              <FieldDTO
                control={control}
                inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                type="text"
                component={TextInput}
                dtoObject={{
                  name: 'search',
                  type: String,
                  validators: [],
                  labelKey: 'search',
                }}
                className="small no-border search-field "
              />
              <FieldDTO
                control={control}
                type="text"
                component={TextInput}
                placeholder="ИД клиента"
                dtoObject={{
                  name: 'id',
                  type: String,
                  validators: [],
                  labelKey: 'client.client_id',
                }}
                className="small no-border default-field"
              />
              <FieldDTO
                control={control}
                type="text"
                dtoObject={{
                  name: 'type',
                  type: String,
                  validators: [],
                  labelKey: 'client.typeClient',
                }}
                addPlaceholder
                component={CustomSelectInput}
                options={clientTypeOptions}
                className="select-field no-border"
              />
              {/* <SeparateBox />
              <IconBox icon="export" /> */}
              <SeparateBox />
              <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
              <SeparateBox />
              <IconBox icon="plus-add" onClick={() => setIsPopup(true)} />
              {selected.length ? <SeparateBox /> : null}
              {selected.length ? <CustomButton text="Удалить" onClick={() => setMode('delete')} icon="trash" /> : null}
              {isOpenFilter
                ? (
                  <ClientLogFilterForm
                    onHide={() => { setIsOpenFilter(false); }}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    reset={reset}
                    defaultValues={defaultFilterValues}
                  />
                )
                : null}
            </div>
          ) : (
            <div className="client-inputs">
              { mode !== 'create' && mode !== 'views' ? <IconBox icon="trash" onClick={() => { setMode('delete'); setSelected([data ? data.id : selectedRow.id]); }} /> : null }
            </div>
          )}
        </div>
        { !selectedRow && (!mode || mode === 'views' || mode === 'delete') ? <ClientLogGrid setData={setData} removeClient={removeClient} meta={clientListMeta} loadData={loadData} selected={selected} selectedItem={selectedRow} setMode={setMode} setSelected={setSelected} data={clientList} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} /> : null }
        { mode === 'edit' || mode === 'create' ? <ClientForm mode={mode} selectedItem={data} onHide={() => { setMode(false); setSelectedRow(null); setData(''); }} meta={clientListMeta} loadData={loadData} /> : null }
        { data && (mode === 'views') ? <ClientDetailsPopUp setMode={setMode} onHide={() => { setMode(false); setData(''); }} selectedItem={data} /> : null }
        { isPopup ? <ClientAddPopUp setMode={setMode} setSelected={setSelected} onHide={() => { setIsPopup(false); }} /> : null }
        { selectedRow && !mode ? <ClientStatus selectedItem={selectedItem} columns={defaultColumns} loadList={loadData} /> : null }
        { selected && selected.length && mode === 'delete' ? <ClientDeletePopUp setSelected={setSelected} removeClient={removeClient} client_ids={selected} onHide={() => setMode(false)} /> : null }
      </div>
    </div>
  );
}
